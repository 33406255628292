import { useState, useCallback } from 'react';
import { useTheme } from 'styled-components';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { unwrapResult } from '@reduxjs/toolkit';

import { Icon } from '@/core/components/Icons/Icon';
import { Button, ButtonVariant } from '@/core/components/Button';
import { TextField } from '@/core/components/TextField';
import { ShareInput } from '@/core/components/ShareInput';
import { InputLabel } from '@/core/components/FormElements.styles';
import { useAppDispatch } from '@/core/store/store';

import { FolderModalType } from '@/features/Geofeatures/components/GeofeatureFolderModal/GeofeatureFolderModal.types';
import {
  createGeofeaturesFolder,
  editGeofeaturesFolder,
} from '@/features/Geofeatures/store/actions';
import {
  GeofeatureCreateFolderErrorReponse,
  GeofeatureCreateFolderResponse,
} from '@/features/Geofeatures/interfaces';

import {
  FormField,
  DeleteButton,
  ButtonWrapper,
  FormContainer,
} from './GeofeatureFolderForm.styles';
import { GeofeatureFolderFormProps, FormData } from './GeofeatureFolderForm.types';

const schema = yup.object().shape({
  folderName: yup
    .string()
    .required('Folder name is required')
    .max(255, 'Focus name cannot exceed 255 characters'),
  prioritize: yup.boolean(),
  coworkers: yup.array(),
});

export const GeofeatureFolderForm = ({
  type,
  folder,
  onSubmit,
  onCancel,
  onDelete,
}: GeofeatureFolderFormProps) => {
  const { colors } = useTheme();
  const [selectedCoworkers, setSelectedCoworkers] = useState<Array<string>>(folder?.user || []);

  const dispatch = useAppDispatch();

  const formMethods = useForm<FormData>({
    defaultValues: {
      folderName: type === FolderModalType.EDIT ? folder?.name : '',
      prioritize: folder?.isPrioritize || false,
      coworkers: folder?.user || [],
    },
    resolver: yupResolver(schema),
  });

  const { handleSubmit, setError } = formMethods;

  const onFormSubmit = useCallback(
    async (data: FormData) => {
      try {
        let folderData: GeofeatureCreateFolderResponse | undefined = undefined;

        if (type === FolderModalType.ADD) {
          folderData = unwrapResult(
            await dispatch(
              createGeofeaturesFolder({
                name: data.folderName,
                isPrioritize: data.prioritize,
                user: selectedCoworkers,
              })
            )
          );
        } else if (type === FolderModalType.EDIT && folder?.id) {
          folderData = unwrapResult(
            await dispatch(
              editGeofeaturesFolder({
                folderId: folder?.id,
                folderData: {
                  name: data.folderName,
                  isPrioritize: data.prioritize,
                  ...(selectedCoworkers.length ? { user: selectedCoworkers } : {}),
                },
              })
            )
          );
        }

        if (folderData) {
          onSubmit(folderData);
        }
      } catch (error) {
        // @ts-expect-error error is not defined
        if (error.formErrors) {
          // @ts-expect-error error is not defined
          const formErrors = error.formErrors as GeofeatureCreateFolderErrorReponse;

          if (formErrors.name) {
            setError('folderName', {
              message: formErrors.name.join(' '),
            });
          }
        }
      }
    },
    [dispatch, folder?.id, onSubmit, selectedCoworkers, setError, type]
  );

  const handleSelectCoworker = useCallback(
    (coworkerId: string) => {
      setSelectedCoworkers([...selectedCoworkers, coworkerId]);
    },
    [selectedCoworkers]
  );

  const handleRemoveSelectedCoworker = useCallback(
    (coworkerId: string) => {
      setSelectedCoworkers(selectedCoworkers.filter(coworker => coworker !== coworkerId));
    },
    [selectedCoworkers]
  );

  return (
    <FormProvider {...formMethods}>
      <FormContainer onSubmit={handleSubmit(onFormSubmit)}>
        <div>
          <FormField>
            <TextField
              name="folderName"
              placeholder="Folder 1"
              autoComplete="off"
              label="Folder Name"
            />
          </FormField>
          <FormField>
            <InputLabel>Share folder</InputLabel>
            <ShareInput
              onSelect={handleSelectCoworker}
              selectedCoworkersIds={selectedCoworkers}
              handleRemoveSelectedCoworker={handleRemoveSelectedCoworker}
            />
          </FormField>
        </div>

        {type === FolderModalType.EDIT && (
          <DeleteButton
            type="button"
            onClick={onDelete}
          >
            <Icon
              name="Trash"
              size={12}
              color={colors.warning}
            />
            Delete folder
          </DeleteButton>
        )}
        <ButtonWrapper>
          <Button type="submit">SAVE CHANGES</Button>
          <Button
            variant={ButtonVariant.TERTIARY}
            onClick={onCancel}
          >
            CANCEL
          </Button>
        </ButtonWrapper>
      </FormContainer>
    </FormProvider>
  );
};
