import { useState, useEffect, useCallback } from 'react';
import { useTheme } from 'styled-components';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { useAppDispatch } from '@/core/store/store';
import { ThemeVariant } from '@/core/interfaces/common';
import { Heading, Paragraph, Caption } from '@/core/components/Typography';
import { Icon } from '@/core/components/Icons';
import { Switch } from '@/core/components/Switch';
import { Button, ButtonVariant } from '@/core/components/Button';
import { MultiButton } from '@/core/components/MultiButton';
import { SelectComponent } from '@/core/components/Select';
import { TextField } from '@/core/components/TextField';
import {
  createFocus,
  selectFocus,
  updateFocus,
  deleteFocus,
  openFocusModal,
  focusModalSelector,
  getSelectedFocusSelector,
  saveDraftFocus,
  getDraftFocusSelector,
  clearDraftFocus,
} from '@/core/store/reducers/focusesSlice';
import { getAllAppliedFiltersSelector } from '@/core/store/reducers/filtersSlice';
import { DeleteModal } from '@/core/components/DeleteModal';
import { Modal } from '@/core/components/Modal';
import { HEADER_HEIGHT } from '@/core/components/Layout/Layout.styles';
import { EDIT_FOCUS_MODAL_Z_INDEX } from '@/core/constants/zIndex';

import { ShareFocus } from './components/ShareFocus';
import {
  EditFocusContainer,
  EditFocusHeader,
  StyledSection,
  FilterSection,
  SwitchLabel,
  StyledInfo,
  WeeklyContainer,
  DailyContainer,
  FrequencyField,
  ButtonsContainer,
  StyledDeleteButton,
} from './EditFocus.styles';
import { TimeOptions, FormData, EditFocusProps } from './EditFocus.types';
import { CoworkerList } from './components/CoworkerList';
import { EditFilters } from './components/EditFilters';

const tabsData = [
  {
    label: 'Real-time',
    value: TimeOptions.REALTIME,
  },
  {
    label: 'Daily',
    value: TimeOptions.DAILY,
  },
  {
    label: 'Weekly',
    value: TimeOptions.WEEKLY,
  },
];

const schema = yup.object().shape({
  name: yup
    .string()
    .required('Focus name is required')
    .max(255, 'Focus name cannot exceed 255 characters'),
  description: yup.string().max(255, 'Description cannot exceed 255 characters'),
  emailNotifications: yup.boolean(),
  emailFrequency: yup.object().shape({
    frequency: yup.number(),
    hour: yup.string(),
    day: yup.string(),
  }),
});

const daysSelectOptions = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
].map(day => ({
  label: day,
  value: day,
}));

const defaultValuesData: FormData = {
  name: 'New focus',
  description: '',
  emailNotifications: false,
  coworkers: [],
  emailFrequency: undefined,
};

export const EditFocus = ({ isOpen }: EditFocusProps) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { colors, theme } = useTheme();
  const dispatch = useAppDispatch();

  const appliedFilters = useSelector(getAllAppliedFiltersSelector);
  const focusModalMode = useSelector(focusModalSelector);
  const currentFocus = useSelector(getSelectedFocusSelector);
  const draftFocus = useSelector(getDraftFocusSelector);

  const isInCreateMode = focusModalMode === 'create';
  const currentFocusId = currentFocus?.id;

  const formMethods = useForm<FormData>({
    defaultValues: draftFocus?.data || defaultValuesData,
    resolver: yupResolver(schema),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    setValue,
  } = formMethods;

  const emailNotifications = watch('emailNotifications');
  const notificationFrequency = watch('emailFrequency.frequency');

  const closeEditFocus = useCallback(() => {
    // @ts-expect-error we are setting clear to force reset
    reset({ clear: true });

    dispatch(openFocusModal(null));
  }, [dispatch, reset]);

  useEffect(() => {
    if (isInCreateMode) {
      if (draftFocus && draftFocus.draftMode === 'create') {
        reset(draftFocus.data);
      } else {
        reset(defaultValuesData);
      }

      return;
    }

    if (currentFocus) {
      let dataToSet: FormData = {
        name: currentFocus.name,
        description: currentFocus.description,
        emailNotifications: currentFocus.emailNotifications,
        emailFrequency: currentFocus.emailFrequency,
      };

      if (draftFocus && draftFocus.focusId === currentFocusId) {
        dataToSet = draftFocus.data;
      }

      reset(dataToSet);
    }
  }, [currentFocus, reset, isInCreateMode, draftFocus, currentFocusId]);

  useEffect(() => {
    if (!emailNotifications) {
      setValue('emailFrequency.frequency', undefined);
    } else if (!notificationFrequency && notificationFrequency !== TimeOptions.REALTIME) {
      setValue('emailFrequency.frequency', TimeOptions.REALTIME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailNotifications]);

  const onSubmit = (data: FormData) => {
    const dataToSubmit = {
      ...data,
      coworkers: data.coworkers?.map(coworker => coworker.id),
      filters: appliedFilters,
    };

    const focusId = isInCreateMode ? uuidv4() : currentFocusId;

    if (isInCreateMode) {
      dispatch(
        createFocus({
          ...dataToSubmit,
          id: focusId as string,
        })
      );
    } else {
      dispatch(
        updateFocus({
          id: focusId as string,
          ...dataToSubmit,
        })
      );
    }

    dispatch(selectFocus(focusId as string));
    // @ts-expect-error we are setting clear to force reset
    reset({ clear: true });
    closeEditFocus();
  };

  const handleDeleteFocus = () => {
    if (!currentFocusId) return;

    dispatch(deleteFocus(currentFocusId as string));
    setIsDeleteModalOpen(false);
    openFocusModal(null);
  };

  useEffect(() => {
    return () => {
      // @ts-expect-error clear is custom property to force reset
      if (watch().clear) {
        dispatch(clearDraftFocus());
      } else {
        dispatch(
          saveDraftFocus({
            focusId: focusModalMode === 'edit' ? currentFocusId : undefined,
            data: watch(),
            draftMode: focusModalMode,
          })
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeEditFocus}
      matchContentSize
      height={`calc(100vh - ${HEADER_HEIGHT})`}
      zIndex={EDIT_FOCUS_MODAL_Z_INDEX}
      overlayPosition={{
        top: HEADER_HEIGHT,
        right: '0',
        left: 'auto',
      }}
    >
      <EditFocusContainer>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <EditFocusHeader>
              <Heading type="h1">{isInCreateMode ? 'Create Focus' : 'Edit Focus'}</Heading>
              {!isInCreateMode && (
                <ButtonsContainer>
                  <StyledDeleteButton
                    type="button"
                    onClick={() => setIsDeleteModalOpen(true)}
                  >
                    <Icon
                      name="Trash"
                      color={colors.warning}
                    />
                    <Paragraph
                      type="normal"
                      color={colors.warning}
                    >
                      Delete focus
                    </Paragraph>
                  </StyledDeleteButton>
                </ButtonsContainer>
              )}
            </EditFocusHeader>
            <StyledSection>
              <Heading type="h3">Focus details</Heading>
              <Paragraph
                type="normal"
                color={theme === ThemeVariant.LIGHT ? colors.primaryText : colors.secondaryText}
              >
                Name
              </Paragraph>
              <TextField
                name="name"
                placeholder="My focus"
              />
              <Paragraph
                type="normal"
                color={theme === ThemeVariant.LIGHT ? colors.primaryText : colors.secondaryText}
              >
                Description
              </Paragraph>
              <TextField
                name="description"
                placeholder="Here’s a quick description of this focus."
                autoComplete="off"
              />
              {errors.description && (
                <Paragraph
                  type="small-normal"
                  color={colors.warning}
                >
                  {errors.description.message}
                </Paragraph>
              )}
            </StyledSection>
            <StyledSection>
              <Heading type="h3">Email notifications</Heading>
              <Paragraph
                type="normal"
                color={colors.secondaryText}
              >
                Receive email notifications when there’s an update to this focus
              </Paragraph>
              <Controller
                control={control}
                name="emailNotifications"
                defaultValue={false}
                render={({ field }) => (
                  <>
                    <SwitchLabel>
                      <Switch
                        isChecked={field.value || false}
                        onChange={() => field.onChange(!field.value)}
                      />
                      <Caption
                        isBold
                        color={field.value ? colors.primaryText : colors.secondaryText}
                      >
                        RECEIVE EMAIL NOTIFICATIONS ABOUT THIS FOCUS
                      </Caption>
                    </SwitchLabel>
                  </>
                )}
              />
              <Controller
                control={control}
                name="emailFrequency.frequency"
                render={({ field: { value, onChange } }) => (
                  <MultiButton
                    isSmall
                    isDisabled={!emailNotifications}
                    tabsData={tabsData}
                    onChange={onChange}
                    active={value}
                  />
                )}
              />
              {emailNotifications && notificationFrequency === TimeOptions.DAILY && (
                <DailyContainer>
                  <FrequencyField>
                    <Paragraph
                      type="normal"
                      color={colors.secondaryText}
                    >
                      Time of notification
                    </Paragraph>
                    <TextField
                      name="emailFrequency.hour"
                      type="time"
                    />
                  </FrequencyField>
                </DailyContainer>
              )}
              {emailNotifications && notificationFrequency === TimeOptions.WEEKLY && (
                <WeeklyContainer>
                  <FrequencyField>
                    <Paragraph
                      type="normal"
                      color={colors.secondaryText}
                    >
                      Time of notification
                    </Paragraph>
                    <TextField
                      name="emailFrequency.hour"
                      type="time"
                    />
                  </FrequencyField>
                  <FrequencyField>
                    <Paragraph
                      type="normal"
                      color={colors.secondaryText}
                    >
                      Day
                    </Paragraph>
                    <Controller
                      control={control}
                      name="emailFrequency.day"
                      render={({ field: { value, onChange } }) => (
                        <SelectComponent
                          options={daysSelectOptions}
                          value={daysSelectOptions.find(day => day.value === value)}
                          onChange={selectValue => onChange(selectValue?.value)}
                        />
                      )}
                    />
                  </FrequencyField>
                </WeeklyContainer>
              )}
              <StyledInfo>
                <Icon
                  name="CircleInfo"
                  color={colors.secondaryText}
                />
                <Paragraph
                  type="small-normal"
                  color={colors.secondaryText}
                >
                  {emailNotifications
                    ? 'You will receive email notifications every time there’s new update about this focus.'
                    : 'You will NOT receive email notifications.'}
                </Paragraph>
              </StyledInfo>
            </StyledSection>
            <StyledSection>
              <Paragraph type="normal">Share focus</Paragraph>
              <Controller
                control={control}
                name="coworkers"
                render={({ field: { value, onChange } }) => {
                  const coworkersValue = value || [];

                  return (
                    <>
                      <ShareFocus
                        onSelect={person => onChange([...coworkersValue, person])}
                        selectedCoworkers={coworkersValue}
                      />
                      {coworkersValue.length > 0 && (
                        <CoworkerList
                          coworkers={coworkersValue}
                          handleRemoveCoworker={coworkerId =>
                            onChange(coworkersValue.filter(coworker => coworker.id !== coworkerId))
                          }
                        />
                      )}
                    </>
                  );
                }}
              />
            </StyledSection>
            <FilterSection>
              <EditFilters />
            </FilterSection>
            <ButtonsContainer>
              <Button type="submit">SAVE CHANGES</Button>
              <Button
                type="button"
                variant={ButtonVariant.TERTIARY}
                onClick={closeEditFocus}
              >
                CANCEL
              </Button>
            </ButtonsContainer>
          </form>
        </FormProvider>
        <DeleteModal
          isOpen={isDeleteModalOpen}
          title="Delete 'My focus'?"
          content={
            <Paragraph type="normal">
              Caution: Deleting this focus will also remove it from other users it is shared with.
            </Paragraph>
          }
          confirmButtonText="YES, DELETE"
          onClose={() => setIsDeleteModalOpen(false)}
          onDelete={handleDeleteFocus}
        />
      </EditFocusContainer>
    </Modal>
  );
};
