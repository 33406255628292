import { MapRef } from 'react-map-gl/maplibre';
import { Feature } from 'geojson';
import { Units, buffer } from '@turf/turf';

import { LineGEOJSONData } from '@/core/interfaces/geojsons';

export const getLineDataName = (dataId: string) => `line-data-${dataId}`;
export const getLineLayerName = (dataId: string) => `line-layer-${dataId}`;
export const getLineBufferDataName = (dataId: string) => `line-buffer-data-${dataId}`;
export const getLineBufferFillLayerName = (dataId: string) => `line-buffer-fill-${dataId}`;
export const getLineBufferStrokeLayerName = (dataId: string) => `line-buffer-stroke-${dataId}`;

type DrawLineBufferData = {
  lineDataGeoJSON: Feature;
  lineBufferDataName: string;
  lineBufferFillLayerName: string;
  lineBufferStrokeLayerName: string;
  radius: number;
  radiusUnit: Units;
  color: string;
};

export const getLineBufferPolygon = (
  lineDataGeoJSON: Feature,
  radius: number,
  radiusUnit: Units
) => {
  return buffer(lineDataGeoJSON, radius, {
    steps: 80,
    units: radiusUnit,
  });
};

export const drawLineBuffer = (
  mapRef: MapRef | null,
  {
    lineDataGeoJSON,
    lineBufferDataName,
    lineBufferFillLayerName,
    lineBufferStrokeLayerName,
    color,
    radius,
    radiusUnit,
  }: DrawLineBufferData
) => {
  if (!mapRef) {
    return;
  }

  const map = mapRef.getMap();
  const lineBufferDataSource = map.getSource(lineBufferDataName);
  const lineBufferFillSource = map.getSource(lineBufferFillLayerName);
  const lineBufferFillLayer = map.getLayer(lineBufferFillLayerName);
  const lineBufferStrokeSource = map.getSource(lineBufferStrokeLayerName);
  const lineBufferStrokeLayer = map.getLayer(lineBufferStrokeLayerName);

  const bufferPolygon = getLineBufferPolygon(lineDataGeoJSON, radius, radiusUnit);

  if (!lineBufferDataSource) {
    map.addSource(lineBufferDataName, {
      type: 'geojson',
      data: bufferPolygon,
    });
  } else {
    // @ts-expect-error - setData is not available in the types
    lineBufferDataSource.setData(bufferPolygon);
  }

  if (lineBufferFillLayer) {
    map.removeLayer(lineBufferFillLayerName);
  }

  if (lineBufferFillSource) {
    map.removeSource(lineBufferFillLayerName);
  }

  if (radius) {
    map.addLayer({
      id: lineBufferFillLayerName,
      type: 'fill',
      source: lineBufferDataName,
      paint: {
        'fill-color': color,
        'fill-opacity': 0.2,
      },
    });
  }

  if (lineBufferStrokeLayer) {
    map.removeLayer(lineBufferStrokeLayerName);
  }

  if (lineBufferStrokeSource) {
    map.removeSource(lineBufferStrokeLayerName);
  }

  if (radius) {
    map.addLayer({
      id: lineBufferStrokeLayerName,
      type: 'line',
      source: lineBufferDataName,
      paint: {
        'line-color': color,
        'line-width': 2,
      },
    });
  }
};

export const drawLine = (
  mapRef: MapRef | null,
  {
    color,
    lineData,
    lineDataName,
    lineLayerName,
    lineBufferDataName,
    lineBufferFillLayerName,
    lineBufferStrokeLayerName,
    radius,
    radiusUnit: radiusUnits,
  }: {
    lineData: LineGEOJSONData;
    color: string;
    lineDataName: string;
    lineLayerName: string;
  } & Omit<DrawLineBufferData, 'lineDataGeoJSON'>
) => {
  if (!mapRef) {
    return;
  }

  const map = mapRef.getMap();

  const lineSource = map.getSource(lineDataName);
  const lineLayer = map.getLayer(lineLayerName);

  if (lineSource) {
    // @ts-expect-error - setData is not available in the types
    lineSource.setData(lineData);
  } else {
    map.addSource(lineDataName, {
      type: 'geojson',
      data: lineData,
    });
  }

  if (lineLayer) {
    map.setPaintProperty(lineLayerName, 'line-color', color);
  } else {
    map.addLayer({
      id: lineLayerName,
      type: 'line',
      source: lineDataName,
      paint: {
        'line-color': color,
        'line-width': 2,
      },
    });
  }

  if (radius) {
    drawLineBuffer(mapRef, {
      color,
      lineDataGeoJSON: lineData,
      lineBufferDataName,
      lineBufferFillLayerName,
      lineBufferStrokeLayerName,
      radius,
      radiusUnit: radiusUnits,
    });
  }
};

export const drawDashLineBetweenPoints = (
  mapRef: MapRef | null,
  {
    coordinates,
    lineDataName,
    lineLayerName,
    color,
  }: {
    coordinates: Array<Array<number>>;
    lineDataName: string;
    lineLayerName: string;
    color: string;
  }
) => {
  if (!mapRef) {
    return;
  }

  const map = mapRef.getMap();

  const lineSource = map.getSource(lineDataName);
  const lineLayer = map.getLayer(lineLayerName);

  const lineData = {
    type: 'Feature',
    geometry: {
      coordinates,
      type: 'LineString',
    },
  };

  if (lineSource) {
    // @ts-expect-error - setData is not available in the types
    lineSource.setData(lineData);
  } else {
    map.addSource(lineDataName, {
      type: 'geojson',
      data: lineData,
    });
  }

  if (lineLayer) {
    map.setPaintProperty(lineLayerName, 'line-color', color);
  } else {
    map.addLayer({
      id: lineLayerName,
      type: 'line',
      source: lineDataName,
      paint: {
        'line-color': color,
        'line-width': 2,
        'line-dasharray': [2, 2],
      },
    });
  }
};

export const removeLineGeofeature = (
  mapRef: MapRef | null,
  {
    lineDataName,
    lineLayerName,
    lineBufferDataName,
    lineBufferFillLayerName,
    lineBufferStrokeLayerName,
  }: {
    lineDataName: string;
    lineLayerName: string;
    lineBufferDataName?: string;
    lineBufferFillLayerName?: string;
    lineBufferStrokeLayerName?: string;
  }
) => {
  if (!mapRef) {
    return;
  }

  const map = mapRef.getMap();

  const dataNamesToRemove = [
    lineDataName,
    lineLayerName,
    lineBufferDataName,
    lineBufferFillLayerName,
    lineBufferStrokeLayerName,
  ];

  dataNamesToRemove.forEach(dataName => {
    if (dataName && map.getLayer(dataName)) {
      map.removeLayer(dataName);
    }
  });

  dataNamesToRemove.forEach(dataName => {
    if (dataName && map.getSource(dataName)) {
      map.removeSource(dataName);
    }
  });
};
