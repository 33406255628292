export enum FilterType {
  CATEGORY = 'category',
  LOCATION = 'location',
  TIMEFRAME = 'timeframe',
  KEYWORDS = 'keywords',
  MORE_FILTERS = 'moreFilters',
}

export type AppliedFilterDataItem = {
  label: string;
  value: string | number;
};

export type AppliedFilterData = Array<AppliedFilterDataItem>;

export type AppliedFilters = {
  [key in FilterType]?: AppliedFilterData;
};

export type ApplyFilterPayload = {
  filterType: FilterType;
  filterData: AppliedFilterData;
};
