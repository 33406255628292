import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';

import { SelectOptionType } from '@/core/components/Select/SelectComponent.types';
import { ColorPicker } from '@/core/components/ColorPicker';
import { IconPicker } from '@/core/components/IconPicker';
import { TextField } from '@/core/components/TextField';
import { SelectComponent } from '@/core/components/Select';
import { Button, ButtonVariant } from '@/core/components/Button';
import { LocationSearch } from '@/core/components/LocationSearch';
import { PointOfInterestGeofenceFormData } from '@/core/components/CreatePointOfInterest/CreatePointOfInterest.types';

import { getFoldersListSelector } from '@/features/Geofeatures/store';

import {
  ButtonsContainer,
  FieldsContainer,
  PickersRow,
} from './PointOfInterestGeofenceForm.styles';
import { PointOfInterestGeofenceFormProps } from './PointOfInterestGeofenceForm.types';

export const PointOfInterestGeofenceForm = ({
  isSubmitting,
  onSubmit,
  onCancel,
}: PointOfInterestGeofenceFormProps) => {
  const geofeaturesFoldersList = useSelector(getFoldersListSelector);

  const foldersList = useMemo<Array<SelectOptionType<string | number>>>(
    () =>
      geofeaturesFoldersList.map(folder => ({
        label: folder.name,
        value: folder.id,
      })),
    [geofeaturesFoldersList]
  );

  const { handleSubmit, control } = useFormContext<PointOfInterestGeofenceFormData>();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FieldsContainer>
        <PickersRow>
          <Controller
            control={control}
            name="color"
            render={({ field: { value, onChange } }) => (
              <ColorPicker
                onColorChange={onChange}
                label="Color"
                color={value}
              />
            )}
          />
          <Controller
            control={control}
            name="icon"
            render={({ field: { value, onChange } }) => (
              <IconPicker
                onIconChange={onChange}
                label="Icon"
                icon={value}
              />
            )}
          />
        </PickersRow>
        <Controller
          control={control}
          name="center"
          render={({ field: { value, onChange } }) => (
            <LocationSearch
              label="Define center (or click on the map)"
              placeholder="Address, city or coordinates"
              location={value}
              onSelectLocation={onChange}
            />
          )}
        />
        <TextField
          name="name"
          label="Name"
        />
        <Controller
          control={control}
          name="folder"
          render={({ field: { name, value, onChange } }) => (
            <SelectComponent
              label="Folder"
              name={name}
              value={value}
              onChange={onChange}
              placeholder="Select folder"
              options={foldersList}
            />
          )}
        />
        <TextField
          name="description"
          label="Description"
        />
        <Controller
          name="tags"
          control={control}
          render={({ field: { name, value, onChange } }) => (
            <SelectComponent
              creatable
              isMulti
              name={name}
              label="Tags"
              value={value}
              placeholder="Select tags"
              onChange={onChange}
            />
          )}
        />
      </FieldsContainer>
      <ButtonsContainer>
        <Button
          type="submit"
          disabled={isSubmitting}
        >
          SAVE
        </Button>
        <Button
          variant={ButtonVariant.TERTIARY}
          onClick={onCancel}
        >
          CANCEL
        </Button>
      </ButtonsContainer>
    </form>
  );
};
