import { createContext, useCallback, useState } from 'react';
import { MapRef } from 'react-map-gl/maplibre';

import { MapProviderProps } from './MapContext.types';

export type MapContextType = {
  mapRef: MapRef | null;
  disableMapHoverInteraction?: boolean;
  setMapRef: (ref: MapRef | null) => void;
  setDisableMapHoverInteraction: (disable: boolean) => void;
};

export const MapContext = createContext<MapContextType>({
  mapRef: null,
  disableMapHoverInteraction: false,
  setMapRef: () => {},
  setDisableMapHoverInteraction: () => {},
});

export const MapProvider = ({ children }: MapProviderProps) => {
  const [mapRef, setMapRef] = useState<MapRef | null>(null);
  const [disableMapHoverInteraction, setDisableMapHoverInteraction] = useState<boolean>(false);

  const setMapRefHandler = useCallback<MapContextType['setMapRef']>(ref => {
    if (ref) {
      setMapRef(ref);
    }
  }, []);

  return (
    <MapContext.Provider
      value={{
        mapRef,
        disableMapHoverInteraction,
        setMapRef: setMapRefHandler,
        setDisableMapHoverInteraction,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};
