import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';

import {
  GEOJSONCollectionData,
  GEOJSONShapeType,
  PointOfInterestGEOJSONData,
} from '@/core/interfaces/geojsons';
import { colorPickerColors } from '@/core/constants/colors';
import { api } from '@/core/api';
import { IconPickerIcons } from '@/core/constants/icons';

const featureSchema = yup.object().shape({
  type: yup
    .string()
    .test('feature', 'Invalid feature type', val => val === 'Feature')
    .required(),
  geometry: yup.object().shape({
    type: yup
      .string()
      .test(
        'feature',
        'Invalid type',
        val => !!val && ['Point', 'LineString', 'Polygon'].includes(val)
      )
      .required(),
    coordinates: yup.array().of(yup.number()).required(),
  }),
  properties: yup.object(),
});

const featureCollectionSchema = yup.object().shape({
  type: yup
    .string()
    .test('feature', 'Invalid collection type', val => val === 'FeatureCollection')
    .required(),
  features: yup.array().of(featureSchema).required(),
});

const geoJSONSchema = yup.lazy(value => {
  if (value.type === 'FeatureCollection') {
    return featureCollectionSchema;
  }

  return featureSchema;
});

export const parseGeoJSON = (geoJSON: string): { [key: string]: unknown } => JSON.parse(geoJSON);

export const isGeoJSONValid = (geoJSON: { [key: string]: unknown }): boolean => {
  try {
    geoJSONSchema.validateSync(geoJSON);

    return true;
  } catch (error) {
    return false;
  }
};

export const isGeoJSON = (geoJSON: string): boolean => {
  try {
    JSON.parse(geoJSON);

    return true;
  } catch (error) {
    return false;
  }
};

const pointOfInterestSchema = yup.object().shape({
  type: yup
    .string()
    .test('feature', 'Invalid feature type', val => val === 'Feature')
    .required(),
  geometry: yup.object().shape({
    type: yup
      .string()
      .test('feature', 'Invalid type', val => val === 'Point')
      .required(),
    coordinates: yup.array().of(yup.number()).required(),
  }),
  properties: yup.object(),
});

export const isGeoJSONPointsOfInterestValid = (geoJSON: { [key: string]: unknown }): boolean => {
  if (geoJSON.type === 'FeatureCollection') {
    try {
      // @ts-expect-error features not types
      geoJSON.features.forEach((feature: { [key: string]: unknown }) => {
        pointOfInterestSchema.validateSync(feature);
      });

      return true;
    } catch (error) {
      return false;
    }
  }

  try {
    pointOfInterestSchema.validateSync(geoJSON);

    return true;
  } catch (error) {
    return false;
  }
};

export const transformPointsOfInterestFromTextToGeoJSON = async (
  text: string
): Promise<PointOfInterestGEOJSONData | GEOJSONCollectionData<PointOfInterestGEOJSONData>> => {
  const pointsOfInterestsRowsColumns = text.split('\n').map(row => row.split('\t'));
  const pointsOfInterestsHeader = pointsOfInterestsRowsColumns.shift()!;
  const pointsOfInterestsList: Array<PointOfInterestGEOJSONData> = [];
  const defaultColor = Object.values(colorPickerColors)[0];

  for (const columns of pointsOfInterestsRowsColumns) {
    let coordinates: [number, number] = [0, 0];
    let name = '';
    let address = '';
    let icon: IconPickerIcons = 'Flag';

    columns.forEach((column, index) => {
      if (pointsOfInterestsHeader[index] === 'Name') {
        name = column;
      } else if (pointsOfInterestsHeader[index] === 'Address') {
        address = column;
      } else if (pointsOfInterestsHeader[index] === 'Icon' && column) {
        icon = column as IconPickerIcons;
      }
    });

    if (!address || !name) {
      continue;
    }

    const predictionResponse = await api.getAddressPrediction(address);

    if (!predictionResponse.data.predictions.length) {
      continue;
    }

    const addressDetails = await api.getAddressDetails(
      predictionResponse.data.predictions[0].placeId
    );

    if (!addressDetails.data.result.geometry) {
      continue;
    }

    coordinates = [
      addressDetails.data.result.geometry.location.lat,
      addressDetails.data.result.geometry.location.lng,
    ];

    pointsOfInterestsList.push({
      type: 'Feature',
      geometry: {
        coordinates,
        type: 'Point',
      },
      properties: {
        id: uuidv4(),
        color: defaultColor,
        icon,
        name,
        description: '',
        tags: [],
        shape: GEOJSONShapeType.POI,
        zoneRadius: 0,
        zoneRadiusUnit: 'kilometers',
        address,
      },
    });
  }

  if (pointsOfInterestsList.length === 1) {
    return pointsOfInterestsList[0];
  }

  return {
    type: 'FeatureCollection',
    features: pointsOfInterestsList,
  };
};
