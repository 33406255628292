import {
  ChangePasswordRequestData,
  OrganizationUsersResponse,
  UpdateOrganizationUserRoleData,
  ForgotPasswordRequestPayload,
  ResetPasswordRequestPayload,
  ResetPasswordRequestQueryParams,
  User,
  UserOrganizationCurrentSubscription,
  UserUpdateResponse,
} from '@/features/Auth/interfaces';

import { request } from '@/utils/request';
import { makeServerSideRequest } from '@/utils/serverSideRequest';

export const makeApi = (instance = request) => ({
  getUser: () => instance.get<User>('user/'),
  sendForgotPassword: (data: ForgotPasswordRequestPayload) =>
    instance.post<void>('password/reset/request/', data),
  sendResetPassword: (
    queryParams: ResetPasswordRequestQueryParams,
    data: ResetPasswordRequestPayload
  ) =>
    instance.patch<void>('password/reset/', data, {
      params: queryParams,
    }),
  getUserOrganizationSubscription: (organizationId: string) =>
    instance.get<UserOrganizationCurrentSubscription>(
      `organizations/${organizationId}/current/subscription/`
    ),
  updateUser: (userId: string, userData: FormData) =>
    instance.put<UserUpdateResponse>(`user/${userId}/profile/update/`, userData),
  changePassword: (userId: string, data: ChangePasswordRequestData) =>
    instance.post<void>(`user/${userId}/password/update/`, data),
  getOrganizationUsers: (organizationId: string) =>
    instance.get<OrganizationUsersResponse>(`organizations/${organizationId}/users/`),
  updateOrganizationUserRole: (userId: string, data: UpdateOrganizationUserRoleData) =>
    instance.put<void>(`user/${userId}/update/group/`, data),
});

export const api = makeApi();
export const makeServerSideApi = (token?: string) => makeApi(makeServerSideRequest(token));
