export const dummyFocuses = [
  {
    id: 1,
    name: 'My focus 1',
  },
  {
    id: 2,
    name: 'My focus 2',
  },
  {
    id: 3,
    name: 'My focus 3',
  },
];

export const dummySharedFocuses = [
  {
    id: 4,
    name: 'My focus 1',
  },
  {
    id: 5,
    name: 'My focus 2',
  },
  {
    id: 6,
    name: 'My focus 3',
  },
];
