import { GeofenceType } from '@/core/components/MapFilters/LocationFilter/components/CreateGeofenceView';
import { LocationSubViewType } from '@/core/components/MapFilters/LocationFilter/LocationFilter.types';
import { AppliedFilters, FilterType } from '@/core/interfaces/filters';
import { timeframeRadioButtonData } from '@/core/components/MapFilters/TimeframeFilter/timeframeRadioButtonData';

export const geofenceTypeToLocationSubViewType: { [key in GeofenceType]: LocationSubViewType } = {
  [GeofenceType.RADIAL]: LocationSubViewType.GEOFENCE_CREATE_RADIAL,
  [GeofenceType.POLYGON]: LocationSubViewType.GEOFENCE_CREATE_POLYGON,
  [GeofenceType.LINE]: LocationSubViewType.GEOFENCE_CREATE_LINE,
};

const defaultTimeframe = timeframeRadioButtonData[0];

export const DEFAULT_FILTERS_DATA: AppliedFilters = {
  [FilterType.TIMEFRAME]: [
    {
      label: defaultTimeframe.name as string,
      value: String(defaultTimeframe.value),
    },
  ],
};
