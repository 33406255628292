import { AddLayerObject } from 'maplibre-gl';
import { MapRef } from 'react-map-gl/maplibre';
import { circle, Units } from '@turf/turf';

import { PointCoordinates } from '@/core/interfaces/common';

export const pointOfInterestFillLayerPrefix = 'poi-fill-';

export const getPointOfInterestDataName = (dataId: string) => `poi-data-${dataId}`;
export const getPointOfInterestFillLayerName = (dataId: string) =>
  `${pointOfInterestFillLayerPrefix}${dataId}`;
export const getPointOfInterestIconLayerName = (dataId: string) => `poi-icon-${dataId}`;

export const getIconForTheme = (icon: string, isDarkMode: boolean) =>
  `${icon}${isDarkMode ? 'Dark' : 'Light'}Mode`;

export const POINT_OF_INTEREST_RADIUS = 20;
export const POINT_OF_INTEREST_CIRCLE_OPACITY = 0.2;
export const POINT_OF_INTEREST_CIRCLE_STROKE_WIDTH = 1;
export const POINT_OF_INTEREST_ICON_SIZE = 16 / 16;

export const drawPointOfInterestGeofeature = (
  mapRef: MapRef | null,
  {
    center,
    color,
    poiDataName,
    poiFillLayerName,
    poiIconLayerName,
    icon,
    properties,
    zoneRadius,
    zoneRadiusUnit,
  }: {
    center: PointCoordinates | null;
    color: string;
    poiDataName: string;
    poiFillLayerName: string;
    poiIconLayerName: string;
    icon: string;
    properties?: { [key: string]: unknown };
    zoneRadius?: number;
    zoneRadiusUnit?: Units;
  }
) => {
  if (!mapRef || !center) {
    return;
  }

  const map = mapRef.getMap();

  const poiPolygon = {
    type: 'Feature',
    geometry: {
      coordinates: center,
      type: 'Point',
    },
    properties,
    id: poiDataName,
  };

  const poiSource = map.getSource(poiDataName);
  const poiFillSource = map.getSource(poiFillLayerName);
  const poiFillLayer = map.getLayer(poiFillLayerName);
  const poiIconLayer = map.getLayer(poiIconLayerName);

  if (poiSource) {
    // @ts-expect-error - setData is not available in the types
    poiSource.setData(poiPolygon);
  } else {
    map.addSource(poiDataName, {
      type: 'geojson',
      data: poiPolygon,
    });
  }

  if (poiFillLayer) {
    map.removeLayer(poiFillLayerName);
  }

  if (poiFillSource) {
    map.removeSource(poiFillLayerName);
  }

  let layerData: AddLayerObject = {
    id: poiFillLayerName,
    type: 'circle',
    source: poiDataName,
    paint: {
      'circle-radius': POINT_OF_INTEREST_RADIUS,
      'circle-color': color,
      'circle-opacity': POINT_OF_INTEREST_CIRCLE_OPACITY,
      'circle-stroke-color': color,
      'circle-stroke-width': POINT_OF_INTEREST_CIRCLE_STROKE_WIDTH,
    },
  };

  if (zoneRadius) {
    const options: {
      steps?: number;
      units?: Units;
    } = {
      steps: 80,
      units: zoneRadiusUnit,
    };
    const circleZonePolygon = circle(center, zoneRadius, options);

    layerData = {
      id: poiFillLayerName,
      type: 'fill',
      source: {
        type: 'geojson',
        data: circleZonePolygon,
      },
      paint: {
        'fill-color': color,
        'fill-opacity': 0.2,
      },
    };
  }

  map.addLayer(layerData);

  if (poiIconLayer) {
    map.setLayoutProperty(poiIconLayerName, 'icon-image', icon);
  } else {
    map.addLayer({
      id: poiIconLayerName,
      type: 'symbol',
      source: poiDataName,
      layout: {
        'icon-image': icon,
        'icon-size': POINT_OF_INTEREST_ICON_SIZE,
      },
    });
  }
};

export const removePointOfInterestGeofeature = (
  mapRef: MapRef | null,
  {
    poiDataName,
    poiFillLayerName,
    poiIconLayerName,
  }: {
    poiDataName: string;
    poiFillLayerName: string;
    poiIconLayerName: string;
  }
) => {
  if (!mapRef) {
    return;
  }

  const map = mapRef.getMap();
  const poiSource = map.getSource(poiDataName);

  if (poiSource) {
    map.removeLayer(poiFillLayerName);
    map.removeLayer(poiIconLayerName);
    map.removeSource(poiDataName);
  }
};
