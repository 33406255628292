import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/core/interfaces/store';
import { ReportSourceType } from '@/core/interfaces/common';

import { ReportsState } from '@/features/Reports/interfaces';

const reportsSelector = (state: RootState): ReportsState => state.reports;

export const getActiveReportIdSelector = createSelector(
  [reportsSelector],
  state => state.activeReportId
);

export const isReportDetailsOpenSelector = createSelector(
  [reportsSelector],
  state => state.activeReportId !== null
);

export const getHorizonReportsListSelector = createSelector(
  [reportsSelector],
  state => state.horizonReportsList
);

export const getLookoutReportsListSelector = createSelector(
  [reportsSelector],
  state => state.lookoutReportsList
);

export const getReportsListSelector = createSelector([reportsSelector], state =>
  [...state.horizonReportsList, ...state.lookoutReportsList].sort((a, b) => {
    const dateAField =
      a.reportSourceType === ReportSourceType.LOOKOUT ? a.modifiedDatetime : a.publishedDatetime;
    const dateBField =
      b.reportSourceType === ReportSourceType.LOOKOUT ? b.modifiedDatetime : b.publishedDatetime;

    const dateA = new Date(dateAField).getTime();
    const dateB = new Date(dateBField).getTime();

    return dateB - dateA;
  })
);
