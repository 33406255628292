import {
  LocationFilterItem,
  LocationFilterViewType,
} from '@/core/components/MapFilters/LocationFilter/LocationFilter.types';
import { getSelectedCountriesFromAppliedFilters } from '@/core/components/MapFilters/LocationFilter/LocationFilter.utils';
import {
  dropdownTimeframeOptions,
  timeframeCustomTimeData,
  timeframeRadioButtonData,
} from '@/core/components/MapFilters/TimeframeFilter/timeframeRadioButtonData';
import { ReportImpact, ReportTypeCategory } from '@/core/interfaces/common';
import { AppliedFilters } from '@/core/interfaces/filters';

import { ReportsListQueryParams } from '@/features/Reports/interfaces';

import {
  getLocationFilterTypeFromItemType,
  getTransformedLocationFilterData,
  toBase64,
} from '@/utils/locations';
import { createGeoJSONStringForExport } from '@/utils/helpers';

const getImpactsFromAppliedFilters = (moreFilters: AppliedFilters['moreFilters']) => {
  return moreFilters
    ? Object.values(ReportImpact).reduce((acc, impactValue) => {
        const impact = moreFilters.find(({ value: filterValue }) => filterValue === impactValue);

        if (impact) {
          acc.push(impactValue);
        }

        return acc;
      }, [] as Array<ReportImpact>)
    : undefined;
};
const getReportTypesFromAppliedFilters = (moreFilters: AppliedFilters['moreFilters']) => {
  return moreFilters
    ? Object.values(ReportTypeCategory).reduce((acc, reportType) => {
        const report = moreFilters.find(({ value: filterValue }) => filterValue === reportType);

        if (report) {
          acc.push(reportType);
        }

        return acc;
      }, [] as Array<ReportTypeCategory>)
    : undefined;
};

const getTimeFrameFromAppliedFilters = (
  timeframe: AppliedFilters['timeframe']
): {
  publishedSinceSeconds?: number;
  startDate?: string;
  endDate?: string;
} => {
  let publishedSinceSeconds: number | undefined;
  let startDate: string | undefined;
  let endDate: string | undefined;
  const appliedFilter = timeframe?.[0];

  if (appliedFilter) {
    // Set state for event date custom timeframe
    if (typeof appliedFilter.value === 'string' && appliedFilter.value.includes(' - ')) {
      const filterDates = appliedFilter.value.split(' - ');

      startDate = filterDates[0];
      endDate = filterDates[1];
      // Set state for event date predefined options
    } else if (dropdownTimeframeOptions.some(option => option.label === appliedFilter.label)) {
      // set new date filter for the days range (for example today or last 7 days)
      // Set state for publication date predefined options
    } else if (timeframeRadioButtonData.some(option => option.name === appliedFilter.label)) {
      const publicationDateOption = timeframeRadioButtonData.find(
        option => option.name === appliedFilter.label
      );

      publishedSinceSeconds = publicationDateOption!.value as number;

      // Set state for publication date custom option
    } else {
      const [customTimeFrameTime, customTimeFrameUnit] = (appliedFilter.value as string).split(' ');
      const publicationDateCustomTime = timeframeCustomTimeData.find(
        option => option.name === customTimeFrameUnit
      );

      if (publicationDateCustomTime) {
        publishedSinceSeconds =
          Number(customTimeFrameTime) * publicationDateCustomTime.amountInSeconds;
      }
    }
  }

  return {
    startDate,
    endDate,
    publishedSinceSeconds,
  };
};

const getLocationsFromAppliedFilters = (
  location: AppliedFilters['location']
): { countries: Array<number> | undefined; geofeatures: string | undefined } => {
  let countries: Array<number> = [];
  let geofeatures: string | undefined = undefined;

  if (location) {
    const transformedAppliedFilters = location.reduce((acc, appliedFilter) => {
      const itemValue = getTransformedLocationFilterData(String(appliedFilter.value));
      const filterType = getLocationFilterTypeFromItemType(itemValue.locationItemType);

      if (filterType === undefined) {
        return acc;
      }

      return [
        ...acc,
        {
          label: appliedFilter.label,
          value: appliedFilter.value,
          locationType: filterType,
          transformedLocationValue: itemValue,
        } as LocationFilterItem,
      ];
    }, [] as Array<LocationFilterItem>);

    countries = getSelectedCountriesFromAppliedFilters(transformedAppliedFilters).map(
      country => country.id
    );

    const geofeaturesList = transformedAppliedFilters
      .filter(filterItem =>
        [LocationFilterViewType.GEOFENCE, LocationFilterViewType.POI].includes(
          filterItem.locationType
        )
      )
      .map(filterItem => filterItem.transformedLocationValue.value);

    // @ts-expect-error geofeaturesList has correct type
    geofeatures = toBase64(createGeoJSONStringForExport(geofeaturesList));
  }

  return {
    countries: countries.length ? countries : undefined,
    geofeatures,
  };
};

export const transformAppliedFiltersToQueryParams = (
  filters: AppliedFilters
): ReportsListQueryParams => {
  const { category, keywords, location, moreFilters, timeframe } = filters;

  const { startDate, endDate, publishedSinceSeconds } = getTimeFrameFromAppliedFilters(timeframe);

  const { countries, geofeatures } = getLocationsFromAppliedFilters(location);

  return {
    category: category ? category.map(({ value }) => value as number) : undefined,
    country: countries,
    eventEndDate: endDate,
    impact: getImpactsFromAppliedFilters(moreFilters),
    keyword: keywords ? keywords.map(({ value }) => (value as string).split(' - ')[0]) : undefined,
    publishedSinceSeconds,
    reportType: getReportTypesFromAppliedFilters(moreFilters),
    eventStartDate: startDate,
    geofeatureCoordinates: geofeatures,
  };
};
