import { useCallback, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { signOut } from 'next-auth/react';

import { Avatar } from '@/core/components/Layout/Avatar';
import { useOnClickOutside } from '@/core/hooks/useOnClickOutside';
import { routePaths } from '@/core/constants/routes';

import { getUserSelector } from '@/features/Auth/store';

import {
  AccountLogoutButton,
  AccountLogoutContainer,
  AccountMenuContainer,
  AccountMenuHeader,
  AccountMenuItem,
  AccountMenuLink,
  AccountMenuList,
  MenuContainer,
  UserEmail,
  UserName,
} from './AccountMenu.styles';

export const AccountMenu = () => {
  const { replace, events } = useRouter();
  const accountMenuRef = useRef(null);
  const userData = useSelector(getUserSelector);
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);

  const closeAccountMenu = useCallback(() => {
    setIsAccountMenuOpen(false);
  }, []);

  const toggleAccountMenu = useCallback(() => {
    setIsAccountMenuOpen(prev => !prev);
  }, []);

  useOnClickOutside(accountMenuRef, closeAccountMenu);

  const onLogoutClick = useCallback(() => {
    try {
      signOut();

      replace('/login');
    } catch (error) {
      console.log('error: ', error);
    }
  }, [replace]);

  useEffect(() => {
    events.on('routeChangeStart', closeAccountMenu);

    return () => {
      events.off('routeChangeStart', closeAccountMenu);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AccountMenuContainer ref={accountMenuRef}>
      <Avatar
        isActive={isAccountMenuOpen}
        onClick={toggleAccountMenu}
        src={userData?.profilePicture}
      />
      {isAccountMenuOpen && (
        <MenuContainer>
          <AccountMenuHeader>
            <Avatar src={userData?.profilePicture} />
            <div>
              <UserName>
                {userData?.firstName} {userData?.lastName}
              </UserName>
              <UserEmail>{userData?.email}</UserEmail>
            </div>
          </AccountMenuHeader>
          <AccountMenuList>
            <AccountMenuItem>
              <AccountMenuLink href={routePaths.myAccount}>My account</AccountMenuLink>
            </AccountMenuItem>
            <AccountMenuItem>
              <AccountMenuLink href={routePaths.myAccountSettings}>Settings</AccountMenuLink>
            </AccountMenuItem>
            <AccountMenuItem>
              <AccountMenuLink href={routePaths.myOrganization}>My organization</AccountMenuLink>
            </AccountMenuItem>
          </AccountMenuList>
          <AccountLogoutContainer>
            <AccountLogoutButton
              type="button"
              onClick={onLogoutClick}
            >
              Logout
            </AccountLogoutButton>
          </AccountLogoutContainer>
        </MenuContainer>
      )}
    </AccountMenuContainer>
  );
};
