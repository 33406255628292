import { DefaultTheme } from 'styled-components';

import { ReportImpact } from '@/core/interfaces/common';
import { ButtonVariant } from '@/core/components/Button';

import { fontFace } from './typographyUtilis';

export const categoryColors = {
  Cybersecurity: '#7652B0',
  Economy: '#53B598',
  'Natural disaster': '#72BCD3',
  Politics: '#4F5F67',
  Safety: '#466BD9',
  Sector: '#D99A45',
  Security: '#E36262',
  Travel: '#CB56A3',
} as const;

export const lightTheme: DefaultTheme = {
  theme: 'light',
  colors: {
    teal: '#85CADC',
    white: '#FFFFFF',
    primaryText: '#1A283A',
    secondaryText: '#788298',
    tertiaryButtonText: '#1A283A',
    activeButtonText: '#0F2240',
    active: '#63ACBF',
    warning: '#AA1F1F',
    border: '#DEE0E9',
    modalBackground: '#FFFFFF',
    navbarIcon: '#1A283A',
    addButton: '#9EADC5',
    mapBackground: '#F7F8FB',
    badgeText: {
      light: '#FFFFFF',
      dark: '#061733',
    },
    background: {
      extraLight: '#EEF0F3',
      light: '#FFFFFF',
      medium: '#FBFCFF',
      dark: '#F7F8FB',
    },
    reliability: {
      unknown: '#8C8C8C',
      low: '#C35E59',
      medium: '#C0A256',
      high: '#5CAF78',
    },
    impact: {
      [ReportImpact.LOW]: {
        backgroundColor: '#FFD569',
        textColor: '#061733',
      },
      [ReportImpact.MEDIUM]: {
        backgroundColor: '#F9813E',
        textColor: '#FFFFFF',
      },
      [ReportImpact.HIGH]: {
        backgroundColor: '#F3404B',
        textColor: '#FFFFFF',
      },
      [ReportImpact.EXTREME]: {
        backgroundColor: '#A5244B',
        textColor: '#FFFFFF',
      },
    },
    category: categoryColors,
    categoryIcon: '#F2F4F8',
    filter: {
      primaryText: '#788298',
      hoverText: '#1A283A',
      filterBadgeBgColor: '#7462D3',
    },
    focuses: {
      primaryText: '#15253D',
      background: '#ECEEF4',
      focusBackground: '#E1E5EF',
      active: '#85CADC',
      activeButtonText: '#0F2240',
    },
    geofenceCreateItem: {
      textColor: {
        active: '#F2F4F8',
        inactive: '#1A283A',
      },
      radialBackground: {
        active: '#FB8800',
        inactive: '#FB88000A',
      },
      polygonBackground: {
        active: '#6459A1',
        inactive: '#6459A10A',
      },
      lineBackground: {
        active: '#59A16D',
        inactive: '#59A16D1A',
      },
    },
    accountRole: '#1AA3B6',
    button: {
      [ButtonVariant.PRIMARY]: {
        backgroundDefault: '#1AA3B6',
        backgroundHover: '#2AB4C7',
        backgroundActive: '#1493A5',
        textDefault: '#FFFFFF',
      },
      [ButtonVariant.SECONDARY]: {
        backgroundDefault: '#D8E5F8',
        backgroundHover: '#B7CEEF',
        backgroundActive: '#99B5DF',
        textDefault: '#1A283A',
      },
      [ButtonVariant.TERTIARY]: {
        backgroundDefault: 'transparent',
        backgroundHover: '#F7F8FB',
        backgroundActive: '#E3E3E3',
        textDefault: '#4C5873',
      },
      [ButtonVariant.WARNING]: {
        backgroundDefault: '#AA1F1F',
        backgroundHover: '#A21C1C',
        backgroundActive: '#951818',
        textDefault: '#FFFFFF',
      },
    },
    tags: {
      tagColor: '#D99A45',
    },
    graph: {
      gridColor: '#DEE1E9',
      lineColors: ['#63ACBF', '#FF9B52', '#6459A1'],
    },
    geofenceDrawing: {
      editVertexBackground: '#0F274B',
      editStroke: '#0F274B',
    },
  },
  typography: {
    fontFamily: fontFace,
  },
  reportListItemFontSizeFactor: 1,
  useAnimations: true,
} as const;

export const darkTheme: DefaultTheme = {
  theme: 'dark',
  colors: {
    teal: '#1AA3B6',
    white: '#FFFFFF',
    primaryText: '#F2F4F8',
    secondaryText: '#B6C1D4',
    tertiaryButtonText: '#B7CEEF',
    activeButtonText: '#0F2240',
    active: '#85CADC',
    warning: '#FF8B8B',
    border: '#44577B',
    modalBackground: '#0A1F3F',
    navbarIcon: '#85CADC',
    addButton: '#9EADC5',
    mapBackground: '#0A1B36',
    badgeText: {
      light: '#F2F4F8',
      dark: '#061733',
    },
    background: {
      extraLight: '#29436C',
      light: '#152D53',
      medium: '#0F274B',
      dark: '#061733',
    },
    reliability: {
      unknown: '#8C8C8C',
      low: '#C35E59',
      medium: '#C0A256',
      high: '#5CAF78',
    },
    impact: {
      [ReportImpact.LOW]: {
        backgroundColor: '#FBD87F',
        textColor: '#061733',
      },
      [ReportImpact.MEDIUM]: {
        backgroundColor: '#E8844C',
        textColor: '#F2F4F8',
      },
      [ReportImpact.HIGH]: {
        backgroundColor: '#D24E66',
        textColor: '#F2F4F8',
      },
      [ReportImpact.EXTREME]: {
        backgroundColor: '#932E46',
        textColor: '#F2F4F8',
      },
    },
    category: categoryColors,
    categoryIcon: '#F2F4F8',
    filter: {
      primaryText: '#B6C1D4',
      hoverText: '#F2F4F8',
      filterBadgeBgColor: '#7462D3',
    },
    focuses: {
      primaryText: '#FFFF',
      background: '#374E74',
      focusBackground: '#455D86',
      active: '#85CADC',
      activeButtonText: '#0F2240',
    },
    geofenceCreateItem: {
      textColor: {
        active: '#F2F4F8',
        inactive: '#F2F4F8',
      },
      radialBackground: {
        active: '#FB8800',
        inactive: '#FB880040',
      },
      polygonBackground: {
        active: '#6459A1',
        inactive: '#6459A140',
      },
      lineBackground: {
        active: '#59A16D',
        inactive: '#59A16D40',
      },
    },
    accountRole: '#1AA3B6',
    button: {
      [ButtonVariant.PRIMARY]: {
        backgroundDefault: '#1AA3B6',
        backgroundHover: '#2AB4C7',
        backgroundActive: '##1493A5',
        textDefault: '#FFFFFF',
      },
      [ButtonVariant.SECONDARY]: {
        backgroundDefault: '#1F437C',
        backgroundHover: '#1B3661',
        backgroundActive: '#0C1A30',
        textDefault: '#FFFFFF',
      },
      [ButtonVariant.TERTIARY]: {
        backgroundDefault: 'transparent',
        backgroundHover: '#1B3661',
        backgroundActive: '#1F437C',
        textDefault: '#B7CEEF',
      },
      [ButtonVariant.WARNING]: {
        backgroundDefault: '#FF8B8B',
        backgroundHover: '#E87474',
        backgroundActive: '#DA6868',
        textDefault: '#FFFFFF',
      },
    },
    tags: {
      tagColor: '#8D52B0',
    },
    graph: {
      gridColor: '#16335F',
      lineColors: ['#85CADC', '#FF9B52', '#6459A1'],
    },
    geofenceDrawing: {
      editVertexBackground: '#85CADC',
      editStroke: '#85CADC',
    },
  },
  typography: {
    fontFamily: fontFace,
  },
  reportListItemFontSizeFactor: 1,
  useAnimations: true,
} as const;
