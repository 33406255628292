import { useCallback, useContext, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import differenceWith from 'lodash.differencewith';

import { MapContext } from '@/core/context/MapContext';
import { useMapStyleName } from '@/core/hooks/useMapStyleName';
import {
  GEOJSONData,
  GEOJSONShapeType,
  LineGEOJSONData,
  PointOfInterestGEOJSONData,
  PolygonGEOJSONData,
  RadialGEOJSONData,
} from '@/core/interfaces/geojsons';
import { ThemeVariant } from '@/core/interfaces/common';

import {
  drawRadialGeofeature,
  getCircleDataName,
  getCircleFillLayerName,
  getCircleStrokeLayerName,
  removeRadialGeofeature,
} from '@/utils/map/drawRadial';
import {
  drawPolygon,
  getPolygonDataName,
  getPolygonFillLayerName,
  getPolygonStrokeLayerName,
  removePolygonGeofeature,
} from '@/utils/map/drawPolygon';
import {
  drawLine,
  getLineBufferDataName,
  getLineBufferFillLayerName,
  getLineBufferStrokeLayerName,
  getLineDataName,
  getLineLayerName,
  removeLineGeofeature,
} from '@/utils/map/drawLine';
import {
  drawPointOfInterestGeofeature,
  getIconForTheme,
  getPointOfInterestDataName,
  getPointOfInterestFillLayerName,
  getPointOfInterestIconLayerName,
  removePointOfInterestGeofeature,
} from '@/utils/map/drawPointOfInterest';

type UseDrawGeofeaturesOnMapProps = {
  geofencesList: Array<GEOJSONData>;
  idSuffix?: string;
};

const drawnGeofeatureComparer = (a: GEOJSONData, b: GEOJSONData) =>
  a.properties.id === b.properties.id;

const combineIdWithSuffix = (id: string, suffix?: string) => (suffix ? `${id}-${suffix}` : id);

export const useDrawGeofeaturesOnMap = ({
  geofencesList,
  idSuffix,
}: UseDrawGeofeaturesOnMapProps) => {
  const { mapRef } = useContext(MapContext);
  const { theme } = useTheme();

  const mapStyleName = useMapStyleName();

  const [drawnGeofeatures, setDrawnGeofeatures] = useState<Array<GEOJSONData>>([]);

  const drawRadialGeofence = useCallback(
    (radialData: RadialGEOJSONData) => {
      const circleId = radialData.properties.id;

      drawRadialGeofeature(mapRef, {
        lineType: 'solid',
        center: [radialData.properties.center.lng, radialData.properties.center.lat],
        radius: radialData.properties.radius,
        units: radialData.properties.radiusUnit,
        color: radialData.properties.color,
        circleDataName: getCircleDataName(combineIdWithSuffix(circleId, idSuffix)),
        circleFillLayerName: getCircleFillLayerName(combineIdWithSuffix(circleId, idSuffix)),
        circleStrokeLayerName: getCircleStrokeLayerName(combineIdWithSuffix(circleId, idSuffix)),
      });
    },
    [idSuffix, mapRef]
  );

  const removeRadialGeofence = useCallback(
    (radialId: string) => {
      removeRadialGeofeature(mapRef, {
        circleDataName: getCircleDataName(combineIdWithSuffix(radialId, idSuffix)),
        circleFillLayerName: getCircleFillLayerName(combineIdWithSuffix(radialId, idSuffix)),
        circleStrokeLayerName: getCircleStrokeLayerName(combineIdWithSuffix(radialId, idSuffix)),
      });
    },
    [idSuffix, mapRef]
  );

  const drawPolygonGeofence = useCallback(
    (polygonData: PolygonGEOJSONData) => {
      const polygonId = String(polygonData.properties.id);

      drawPolygon(mapRef, {
        color: polygonData.properties.color,
        polygonData,
        polygonDataName: getPolygonDataName(combineIdWithSuffix(polygonId, idSuffix)),
        polygonFillLayerName: getPolygonFillLayerName(combineIdWithSuffix(polygonId, idSuffix)),
        polygonStrokeLayerName: getPolygonStrokeLayerName(combineIdWithSuffix(polygonId, idSuffix)),
      });
    },
    [idSuffix, mapRef]
  );

  const removePolygonGeofence = useCallback(
    (polygonId: string) => {
      removePolygonGeofeature(mapRef, {
        polygonDataName: getPolygonDataName(combineIdWithSuffix(polygonId, idSuffix)),
        polygonFillLayerName: getPolygonFillLayerName(combineIdWithSuffix(polygonId, idSuffix)),
        polygonStrokeLayerName: getPolygonStrokeLayerName(combineIdWithSuffix(polygonId, idSuffix)),
      });
    },
    [idSuffix, mapRef]
  );

  const drawLineGeofence = useCallback(
    (lineData: LineGEOJSONData) => {
      const lineId = String(lineData.properties.id);

      drawLine(mapRef, {
        color: lineData.properties.color,
        lineData,
        lineDataName: getLineDataName(combineIdWithSuffix(lineId, idSuffix)),
        lineLayerName: getLineLayerName(combineIdWithSuffix(lineId, idSuffix)),
        lineBufferDataName: getLineBufferDataName(combineIdWithSuffix(lineId, idSuffix)),
        lineBufferFillLayerName: getLineBufferFillLayerName(combineIdWithSuffix(lineId, idSuffix)),
        lineBufferStrokeLayerName: getLineBufferStrokeLayerName(
          combineIdWithSuffix(lineId, idSuffix)
        ),
        radius: lineData.properties.radius,
        radiusUnit: lineData.properties.radiusUnit,
      });
    },
    [idSuffix, mapRef]
  );

  const removeLineGeofence = useCallback(
    (lineId: string) => {
      removeLineGeofeature(mapRef, {
        lineDataName: getLineDataName(combineIdWithSuffix(lineId, idSuffix)),
        lineLayerName: getLineLayerName(combineIdWithSuffix(lineId, idSuffix)),
        lineBufferDataName: getLineBufferDataName(combineIdWithSuffix(lineId, idSuffix)),
        lineBufferFillLayerName: getLineBufferFillLayerName(combineIdWithSuffix(lineId, idSuffix)),
        lineBufferStrokeLayerName: getLineBufferStrokeLayerName(
          combineIdWithSuffix(lineId, idSuffix)
        ),
      });
    },
    [idSuffix, mapRef]
  );

  const drawPointOfInterestGeofence = useCallback(
    (pointOfInterest: PointOfInterestGEOJSONData) => {
      const pointOfInterestId = pointOfInterest.properties.id;

      drawPointOfInterestGeofeature(mapRef, {
        center: pointOfInterest.geometry.coordinates,
        color: pointOfInterest.properties.color,
        poiDataName: getPointOfInterestDataName(combineIdWithSuffix(pointOfInterestId, idSuffix)),
        poiFillLayerName: getPointOfInterestFillLayerName(
          combineIdWithSuffix(pointOfInterestId, idSuffix)
        ),
        poiIconLayerName: getPointOfInterestIconLayerName(
          combineIdWithSuffix(pointOfInterestId, idSuffix)
        ),
        icon: getIconForTheme(pointOfInterest.properties.icon, theme === ThemeVariant.DARK),
        properties: pointOfInterest.properties,
        zoneRadius: pointOfInterest.properties.zoneRadius,
        zoneRadiusUnit: pointOfInterest.properties.zoneRadiusUnit,
      });
    },
    [idSuffix, mapRef, theme]
  );

  const removePointOfInterestGeofence = useCallback(
    (pointOfInterestId: string) => {
      removePointOfInterestGeofeature(mapRef, {
        poiDataName: getPointOfInterestDataName(combineIdWithSuffix(pointOfInterestId, idSuffix)),
        poiFillLayerName: getPointOfInterestFillLayerName(
          combineIdWithSuffix(pointOfInterestId, idSuffix)
        ),
        poiIconLayerName: getPointOfInterestIconLayerName(
          combineIdWithSuffix(pointOfInterestId, idSuffix)
        ),
      });
    },
    [idSuffix, mapRef]
  );

  useEffect(() => {
    const geofeaturesToRemove = differenceWith(
      drawnGeofeatures,
      geofencesList,
      drawnGeofeatureComparer
    );

    geofeaturesToRemove.forEach(geofeature => {
      if (geofeature.properties.shape === GEOJSONShapeType.CIRCLE) {
        removeRadialGeofence(geofeature.properties.id);
      } else if (geofeature.properties.shape === GEOJSONShapeType.POLYGON) {
        removePolygonGeofence(geofeature.properties.id);
      } else if (geofeature.properties.shape === GEOJSONShapeType.LINE) {
        removeLineGeofence(geofeature.properties.id);
      } else if (geofeature.properties.shape === GEOJSONShapeType.POI) {
        removePointOfInterestGeofence(geofeature.properties.id);
      }
    });

    geofencesList.forEach(geofeature => {
      if (geofeature.properties.shape === GEOJSONShapeType.CIRCLE) {
        drawRadialGeofence(geofeature as RadialGEOJSONData);
      } else if (geofeature.properties.shape === GEOJSONShapeType.POLYGON) {
        drawPolygonGeofence(geofeature as PolygonGEOJSONData);
      } else if (geofeature.properties.shape === GEOJSONShapeType.LINE) {
        drawLineGeofence(geofeature as LineGEOJSONData);
      } else if (geofeature.properties.shape === GEOJSONShapeType.POI) {
        drawPointOfInterestGeofence(geofeature as PointOfInterestGEOJSONData);
      }
    });

    setDrawnGeofeatures(geofencesList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef, geofencesList, theme, mapStyleName]);

  useEffect(() => {
    return () => {
      geofencesList.forEach(geofeature => {
        if (geofeature.properties.shape === GEOJSONShapeType.CIRCLE) {
          removeRadialGeofence(geofeature.properties.id);
        } else if (geofeature.properties.shape === GEOJSONShapeType.POLYGON) {
          removePolygonGeofence(geofeature.properties.id);
        } else if (geofeature.properties.shape === GEOJSONShapeType.LINE) {
          removeLineGeofence(geofeature.properties.id);
        } else if (geofeature.properties.shape === GEOJSONShapeType.POI) {
          removePointOfInterestGeofence(geofeature.properties.id);
        }
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
