import { useEffect, useState, useRef } from 'react';
import { useTheme } from 'styled-components';

import { Paragraph } from '@/core/components/Typography';
import { useOnClickOutside } from '@/core/hooks/useOnClickOutside';
import { Input } from '@/core/components/Input';
import { Icon } from '@/core/components/Icons';
import { Avatar } from '@/core/components/Layout/Avatar';

import {
  SearchWrapper,
  SuggestionsList,
  Suggestion,
  RoleBadge,
  AddButton,
} from './ShareFocus.styles';
import { ShareFocusProps, Coworker } from './ShareFocus.types';

const suggestions = [
  {
    id: '1',
    name: 'John Doe',
    email: 'john.d@gmail.com',
    role: 'viewer',
    photoUrl: 'https://via.placeholder.com/150',
  },
  {
    id: '2',
    name: 'Jane Smith',
    email: 'jane.s@gmail.com',
    role: 'viewer',
    photoUrl: 'https://via.placeholder.com/150',
  },
  {
    id: '3',
    name: 'Bob Johnson',
    email: 'bob.j@gmail.com',
    role: 'viewer',
    photoUrl: 'https://via.placeholder.com/150',
  },
  {
    id: '4',
    name: 'Alice Williams',
    email: 'alice.w@gmail.com',
    role: 'viewer',
    photoUrl: 'https://via.placeholder.com/150',
  },
  {
    id: '5',
    name: 'Charlie Brown',
    email: 'charlie.b@gmail.com',
    role: 'viewer',
    photoUrl: 'https://via.placeholder.com/150',
  },
  {
    id: '6',
    name: 'David Davis',
    email: 'david.d@gmail.com',
    role: 'viewer',
    photoUrl: 'https://via.placeholder.com/150',
  },
  {
    id: '7',
    name: 'Emily Evans',
    email: 'emily.e@gmail.com',
    role: 'viewer',
    photoUrl: 'https://via.placeholder.com/150',
  },
  {
    id: '8',
    name: 'Frank Foster',
    email: 'frank.f@gmail.com',
    role: 'viewer',
    photoUrl: 'https://via.placeholder.com/150',
  },
  {
    id: '9',
    name: 'Grace Green',
    email: 'grace.g@gmail.com',
    role: 'viewer',
    photoUrl: 'https://via.placeholder.com/150',
  },
  {
    id: '10',
    name: 'Harry Hall',
    email: 'harry.h@gmail.com',
    role: 'viewer',
    photoUrl: 'https://via.placeholder.com/150',
  },
  {
    id: '11',
    name: 'Irene Isaac',
    email: 'irene.i@gmail.com',
    role: 'viewer',
    photoUrl: 'https://via.placeholder.com/150',
  },
  {
    id: '12',
    name: 'Jack Johnson',
    email: 'jack.j@gmail.com',
    role: 'viewer',
    photoUrl: 'https://via.placeholder.com/150',
  },
  {
    id: '13',
    name: 'Kathy King',
    email: 'kathy.k@gmail.com',
    role: 'viewer',
    photoUrl: 'https://via.placeholder.com/150',
  },
  {
    id: '14',
    name: 'Larry Lewis',
    email: 'larry.l@gmail.com',
    role: 'viewer',
    photoUrl: 'https://via.placeholder.com/150',
  },
  {
    id: '15',
    name: 'Molly Moore',
    email: 'molly.m@gmail.com',
    role: 'viewer',
    photoUrl: 'https://via.placeholder.com/150',
  },
  {
    id: '16',
    name: 'Nancy Nelson',
    email: 'nancy.n@gmail.com',
    role: 'viewer',
    photoUrl: 'https://via.placeholder.com/150',
  },
  {
    id: '17',
    name: 'Oscar Oliver',
    email: 'oscar.o@gmail.com',
    role: 'viewer',
    photoUrl: 'https://via.placeholder.com/150',
  },
  {
    id: '18',
    name: 'Patty Peterson',
    email: 'patty.p@gmail.com',
    role: 'viewer',
    photoUrl: 'https://via.placeholder.com/150',
  },
  {
    id: '19',
    name: 'Quincy Quinn',
    email: 'quincy.q@gmail.com',
    role: 'viewer',
    photoUrl: 'https://via.placeholder.com/150',
  },
  {
    id: '20',
    name: 'Randy Roberts',
    email: 'randy.r@gmail.com',
    role: 'viewer',
    photoUrl: 'https://via.placeholder.com/150',
  },
];

export const ShareFocus = ({ onSelect, selectedCoworkers }: ShareFocusProps) => {
  const { colors } = useTheme();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState<Array<Coworker>>([]);

  const searchBarRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(searchBarRef, () => setFilteredSuggestions([]));

  const handleSearch = (term: string) => {
    if (term.length >= 2 && !suggestions.some(suggestion => suggestion.name === term)) {
      const filtered = suggestions.filter(
        suggestion =>
          suggestion.name.toLowerCase().includes(term.toLowerCase()) &&
          !selectedCoworkers.some(coworker => coworker.id === suggestion.id)
      );

      setFilteredSuggestions(filtered);
    } else {
      setFilteredSuggestions([]);
    }
  };

  const onSelectSuggestion = (suggestion: Coworker) => {
    setSearchTerm('');
    onSelect(suggestion);
  };

  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchTerm]);

  return (
    <SearchWrapper ref={searchBarRef}>
      <Input
        placeholder="Search for a user or an organization"
        autoComplete="off"
        suffixIcon={<Icon name="Search" />}
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
      />
      {filteredSuggestions.length > 0 && (
        <SuggestionsList>
          {filteredSuggestions.map((suggestion, index) => (
            <Suggestion key={index}>
              <Avatar src={suggestion.photoUrl} />
              <Paragraph
                type="normal"
                color={colors.secondaryText}
              >
                {suggestion.name}
              </Paragraph>
              <Paragraph
                type="small-normal"
                color={colors.secondaryText}
              >
                {suggestion.email}
              </Paragraph>
              <RoleBadge>{suggestion.role}</RoleBadge>
              <AddButton
                onClick={() => onSelectSuggestion(suggestion)}
                type="button"
              >
                Add
              </AddButton>
            </Suggestion>
          ))}
        </SuggestionsList>
      )}
    </SearchWrapper>
  );
};
