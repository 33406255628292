import { GenericAbortSignal } from 'axios';
import qs from 'qs';

import { ReportsListQueryParams, ReportsListResponse } from '@/features/Reports/interfaces';

import { request } from '@/utils/request';
import { makeServerSideRequest } from '@/utils/serverSideRequest';

export const makeApi = (instance = request) => ({
  getHorizonReportsList: (queryParams: ReportsListQueryParams = {}, signal: GenericAbortSignal) =>
    instance.get<ReportsListResponse>('report/horizon/', {
      params: queryParams,
      signal,
      paramsSerializer: param => qs.stringify(param, { arrayFormat: 'comma' }),
    }),
  getLookoutReportsList: (queryParams: ReportsListQueryParams = {}, signal: GenericAbortSignal) =>
    instance.get<ReportsListResponse>('report/lookout/', {
      params: queryParams,
      signal,
      paramsSerializer: param => qs.stringify(param, { arrayFormat: 'comma' }),
    }),
});

export const api = makeApi();
export const makeServerSideApi = (token: string) => makeApi(makeServerSideRequest(token));
