import { useState, useCallback, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';

import { AppliedFilterData, AppliedFilterDataItem, FilterType } from '@/core/interfaces/filters';
import { applyFilter, getAppliedFilterSelector } from '@/core/store/reducers/filtersSlice';
import { useAppDispatch } from '@/core/store/store';
import { AddButton } from '@/core/components/AddButton';
import { FilterContainer } from '@/core/components/FilterContainer';

import { KeywordsFilterContainer } from './KeywordsFilter.styles';
import { KeywordsFilterRow } from './components/KeywordsFilterRow';
import { DropdownValues } from './components';
import { KeywordsFilterValue } from './KeywordsFilter.types';

export const KeywordsFilter = () => {
  const appliedFilters = useSelector(state => getAppliedFilterSelector(state, FilterType.KEYWORDS));
  const dispatch = useAppDispatch();

  const [rows, setRows] = useState([{ id: uuidv4() }]);
  const [rowValues, setRowValues] = useState<KeywordsFilterValue>({});
  const [lastDropdownValue, setLastDropdownValue] = useState(DropdownValues.AND);

  const setAppliedFilters = useCallback(
    (filtersToApply: AppliedFilterData) => {
      dispatch(
        applyFilter({
          filterType: FilterType.KEYWORDS,
          filterData: filtersToApply,
        })
      );
    },
    [dispatch]
  );

  const onFiltersToggle = () => {
    if (appliedFilters.length === 0) {
      setRows([{ id: uuidv4() }]);
      setRowValues({});
    } else {
      const newRowValues = appliedFilters.reduce((acc, filter) => {
        const [inputValue, dropdownValue] = (filter.value as string).split(' - ');
        const id = uuidv4();

        acc[id] = {
          inputValue,
          dropdownValue: dropdownValue as DropdownValues,
        };

        return acc;
      }, {} as KeywordsFilterValue);

      setRowValues(newRowValues);

      setRows(Object.keys(newRowValues).map(id => ({ id })));
    }
  };

  const handleAddRow = useCallback(() => {
    setRows(prevRows => [
      ...prevRows,
      {
        id: uuidv4(),
      },
    ]);
  }, []);

  const handleRemoveRow = useCallback(
    (rowId: string) => {
      setRows(rows.filter(row => row.id !== rowId));
      setRowValues(prevValues => {
        const newValues = { ...prevValues };

        delete newValues[rowId];

        return newValues;
      });
    },
    [rows]
  );

  const handleRemoveFilterFromList = useCallback(
    (filterToRemove: AppliedFilterDataItem) => {
      const filtersToApply = appliedFilters.filter(filter => filter.value !== filterToRemove.value);

      setAppliedFilters(filtersToApply);

      const rowIdToRemove = Object.keys(rowValues).find(rowId => {
        const { inputValue, dropdownValue } = rowValues[rowId];
        const value = `${inputValue} - ${dropdownValue}`;

        return value === filterToRemove.value;
      });

      if (rowIdToRemove) {
        setRows(prevRows => prevRows.filter(row => row.id !== rowIdToRemove));
        setRowValues(prevValues => {
          const newValues = { ...prevValues };

          delete newValues[rowIdToRemove];

          return newValues;
        });
      }
    },
    [appliedFilters, rowValues, setAppliedFilters]
  );

  const getAppliedFilters = useCallback((filterValues: KeywordsFilterValue) => {
    const keys = Object.keys(filterValues);

    return keys.flatMap(rowId => {
      const { inputValue, dropdownValue } = filterValues[rowId];

      if (inputValue.trim() === '') {
        return [];
      }

      return [
        {
          value: `${inputValue} - ${dropdownValue}`,
          label: `${dropdownValue}: ${inputValue}`,
        },
      ];
    });
  }, []);

  const onFiltersApply = () => {
    setAppliedFilters(getAppliedFilters(rowValues));
  };

  useEffect(() => {
    onFiltersToggle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedFilters]);

  return (
    <FilterContainer
      filterName="Keywords"
      filterType={FilterType.KEYWORDS}
      onFiltersApply={onFiltersApply}
      onFiltersToggle={onFiltersToggle}
      onAppliedFilterRemove={handleRemoveFilterFromList}
    >
      <KeywordsFilterContainer>
        {rows.map((row, index) => (
          <KeywordsFilterRow
            key={row.id}
            inputInitialValue={rowValues[row.id]?.inputValue}
            dropdownInitialValue={rowValues[row.id]?.dropdownValue || lastDropdownValue}
            onDropdownValueChange={setLastDropdownValue}
            onRemoveRow={() => handleRemoveRow(row.id)}
            onAddRow={index === rows.length - 1 ? handleAddRow : undefined}
            onValuesChange={(inputValue: string, dropdownValue: DropdownValues) => {
              setRowValues(prevValues => ({
                ...prevValues,
                [row.id]: {
                  inputValue,
                  dropdownValue,
                },
              }));
            }}
          />
        ))}
        <AddButton
          onClick={handleAddRow}
          text="Add keyword"
        />
      </KeywordsFilterContainer>
    </FilterContainer>
  );
};
