import styled from 'styled-components';

import { getVariant } from '@/themes/typographyUtilis';

export const SearchWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const SuggestionsList = styled.ul`
  position: absolute;
  top: calc(100% + 4px);
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 0;
  border-radius: 6px;
  padding: 10px 0;
  background-color: ${({ theme }) => theme.colors.background.light};
  list-style: none;
`;

export const Suggestion = styled.li`
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr 1fr 100px auto;
  gap: 20px;
  width: 100%;
  padding: 10px 30px;
`;

export const RoleBadge = styled.span`
  width: fit-content;
  border-radius: 4px;
  padding: 4px 11px;
  background-color: ${({ theme }) => theme.colors.active};
  ${getVariant({ variant: 'body2-normal' })}
`;

export const AddButton = styled.button`
  border: 1px solid ${({ theme }) => theme.colors.addButton};
  border-radius: 6px;
  padding: 5px 12px;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.addButton};
  ${getVariant({ variant: 'body2-normal' })}
  cursor: pointer;
`;
