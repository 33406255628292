import { Coworker } from '@/core/components/ShareInput';

export enum TimeOptions {
  REALTIME,
  DAILY,
  WEEKLY,
}
export interface EmailFrequencyType {
  frequency?: TimeOptions;
  hour?: string;
  day?: string;
}
export interface FormData {
  id?: string;
  name: string;
  description?: string;
  emailNotifications?: boolean;
  emailFrequency?: EmailFrequencyType;
  coworkers?: Array<Coworker>;
}

export type EditFocusProps = {
  isOpen: boolean;
};
