import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from '@/core/interfaces/store';
import { FocusType } from '@/core/interfaces/focuses';
import { dummyFocuses, dummySharedFocuses } from '@/core/constants/focuses';

import { FormData as FocusFormData } from '@/features/EditFocus/EditFocus.types';

type FocusModalType = 'edit' | 'create' | null;

export interface FocusesState {
  selectedFocus: FocusType | null;
  focuses: Array<FocusType> | [];
  sharedFocuses: Array<FocusType> | [];
  focusModal: FocusModalType;
  draftFocus: {
    focusId?: string | number;
    data: FocusFormData;
    draftMode: FocusModalType;
  } | null;
}

const initialState: FocusesState = {
  selectedFocus: null,
  focuses: dummyFocuses,
  sharedFocuses: dummySharedFocuses,
  focusModal: null,
  draftFocus: null,
};

export const focusesSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    openFocusModal: (state, { payload }: PayloadAction<'edit' | 'create' | null>) => {
      state.focusModal = payload;
    },
    createFocus: (state, { payload }: PayloadAction<FocusType>) => {
      state.focuses = [...state.focuses, payload];
    },
    updateFocus: (state, { payload }: PayloadAction<FocusType>) => {
      state.focuses = state.focuses.map(focus =>
        focus.id.toString() === payload.id.toString() ? payload : focus
      );
      state.sharedFocuses = state.sharedFocuses.map(focus =>
        focus.id.toString() === payload.id.toString() ? payload : focus
      );
    },
    deleteFocus: (state, { payload }: PayloadAction<string | number>) => {
      state.focuses = state.focuses.filter(focus => focus.id.toString() !== payload.toString());
      state.sharedFocuses = state.sharedFocuses.filter(
        focus => focus.id.toString() !== payload.toString()
      );
    },
    selectFocus: (state, { payload }: PayloadAction<string | number | null>) => {
      const focusId = payload;

      const foundFocus =
        state.focuses.find(focus => focus.id.toString() === focusId?.toString()) ||
        state.sharedFocuses.find(focus => focus.id.toString() === focusId?.toString());

      state.selectedFocus = foundFocus || null;
    },
    saveDraftFocus: (state, { payload }: PayloadAction<FocusesState['draftFocus']>) => {
      state.draftFocus = payload;
    },
    clearDraftFocus: state => {
      state.draftFocus = null;
    },
  },
});

export const {
  createFocus,
  selectFocus,
  deleteFocus,
  updateFocus,
  openFocusModal,
  saveDraftFocus,
  clearDraftFocus,
} = focusesSlice.actions;

const focusesSelector = (state: RootState): FocusesState => state.focuses;

export const getFocusesSelector = createSelector(focusesSelector, state => state.focuses);
export const getSharedFocusesSelector = createSelector(
  focusesSelector,
  state => state.sharedFocuses
);
export const getAllFocusesSelector = createSelector(focusesSelector, state => [
  ...state.focuses,
  ...state.sharedFocuses,
]);
export const getSelectedFocusSelector = createSelector(
  focusesSelector,
  state => state.selectedFocus
);

export const focusModalSelector = createSelector(focusesSelector, state => state.focusModal);

export const iFocusModalOpenSelector = createSelector(
  focusModalSelector,
  state => state === 'edit' || state === 'create'
);

export const getDraftFocusSelector = createSelector(focusesSelector, state => state.draftFocus);

export default focusesSlice.reducer;
