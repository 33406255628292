import { Units } from '@turf/turf';

import { IconPickerIcons } from '@/core/constants/icons';

export type BaseGEOJSONProperties = {
  id: string;
  color: string;
  icon: IconPickerIcons;
  name: string;
  description: string;
  folder?: number;
  tags: Array<string>;
  shape: GEOJSONShapeType;
};

export type RadialGEOJSONData = {
  type: 'Feature';
  geometry: {
    coordinates: Array<Array<Array<number>>>;
    type: 'Polygon';
  };
  properties: BaseGEOJSONProperties & {
    shape: GEOJSONShapeType.CIRCLE;
    center: {
      lat: number;
      lng: number;
    };
    radius: number;
    radiusUnit: Units;
    address: string;
  };
};

export type PolygonGEOJSONData = {
  type: 'Feature';
  geometry: {
    coordinates: Array<Array<Array<number>>>;
    type: 'Polygon';
  };
  properties: BaseGEOJSONProperties & {
    shape: GEOJSONShapeType.POLYGON;
  };
};

export type LineGEOJSONData = {
  type: 'Feature';
  geometry: {
    coordinates: Array<Array<number>>;
    type: 'LineString';
  };
  properties: BaseGEOJSONProperties & {
    shape: GEOJSONShapeType.LINE;
    radius: number;
    radiusUnit: Units;
  };
};

export type PointOfInterestGEOJSONData = {
  type: 'Feature';
  geometry: {
    coordinates: [number, number];
    type: 'Point';
  };
  properties: BaseGEOJSONProperties & {
    shape: GEOJSONShapeType.POI;
    zoneRadius?: number;
    zoneRadiusUnit?: Units;
    address: string;
  };
};

export type GEOJSONData =
  | RadialGEOJSONData
  | PolygonGEOJSONData
  | LineGEOJSONData
  | PointOfInterestGEOJSONData;

export enum GEOJSONShapeType {
  CIRCLE = 'circle',
  POLYGON = 'polygon',
  LINE = 'line',
  POI = 'point',
}

export type GEOJSONCollectionData<FeatureType = GEOJSONData> = {
  type: 'FeatureCollection';
  features: Array<FeatureType>;
};
