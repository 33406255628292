import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { FilterContainer } from '@/core/components/FilterContainer';
import { geofenceTypeToLocationSubViewType } from '@/core/constants/filters';
import { MultiButton } from '@/core/components/MultiButton';
import {
  GEOJSONCollectionData,
  GEOJSONData,
  GEOJSONShapeType,
  LineGEOJSONData,
  PointOfInterestGEOJSONData,
  PolygonGEOJSONData,
  RadialGEOJSONData,
} from '@/core/interfaces/geojsons';
import { AppliedFilterData, AppliedFilterDataItem, FilterType } from '@/core/interfaces/filters';
import {
  applyFilter,
  getAppliedFilterSelector,
  isFilterOpenedSelector,
} from '@/core/store/reducers/filtersSlice';
import { useAppDispatch } from '@/core/store/store';
import { CreateRadialGeofence } from '@/core/components/CreateRadialGeofence';
import { CreatePolygonGeofence } from '@/core/components/CreatePolygonGeofence';
import { CreateLineGeofence } from '@/core/components/CreateLineGeofence';
import { AdjustGeofenceRadius } from '@/core/components/AdjustGeofenceRadius';
import { useDrawGeofeaturesOnMap } from '@/core/hooks/useDrawGeofeaturesOnMap';
import { CountryLocationData } from '@/core/interfaces/locations';

import { getAllGeofeaturesListSelector } from '@/features/Geofeatures/store';

import {
  getTransformedLocationFilterData,
  getLocationFilterTypeFromItemType,
  getStringifiedLocationFilterData,
  getLocationFilterItemTypeFromShape,
} from '@/utils/locations';
import { transformPointsOfInterestFromTextToGeoJSON } from '@/utils/geoJSONImport';

import { GeofencesList } from './components/GeofencesList';
import { FilterLocationContainer, FilterTypeContainer } from './LocationFilter.styles';
import {
  LocationCountryFilterItemValue,
  LocationFilterGeofenceSource,
  LocationFilterItem,
  LocationFilterItemType,
  LocationFilterItemValue,
  LocationFilterViewType,
  LocationGeofenceFilterItem,
  LocationGeofenceFilterItemValue,
  LocationGeofenceFilterLineItemValue,
  LocationGeofenceFilterPolygonItemValue,
  LocationGeofenceFilterRadialItemValue,
  LocationPoiFilterItem,
  LocationPoiFilterItemValue,
  LocationSubViewType,
} from './LocationFilter.types';
import { CreateGeofenceView, GeofenceType } from './components/CreateGeofenceView';
import { CreatedGeofences } from './components/CreateGeofenceView/CreateGeofenceView.types';
import { CountryView } from './components/CountryView';
import { getSelectedCountriesFromAppliedFilters, locationTabs } from './LocationFilter.utils';
import { PointOfInterestsList } from './components/PointOfInterestsList';
import { PointOfInterestImport } from './components/PointOfInterestImport';
import {
  GeofeatureListSuggestionItem,
  GeofeatureListSuggestionItemType,
} from './components/GeofeaturesList';

const FILTER_DEFAULT_VIEW = LocationFilterViewType.COUNTRY;
const FILTER_DEFAULT_SUB_VIEW = null;

export const LocationFilter = () => {
  const allGeofeaturesList = useSelector(getAllGeofeaturesListSelector);
  const appliedFilters = useSelector(state => getAppliedFilterSelector(state, FilterType.LOCATION));
  const [isEditMode, setIsEditMode] = useState(false);

  const transformedAppliedFilters = useMemo<Array<LocationFilterItem>>(
    () =>
      appliedFilters.reduce((acc, appliedFilter) => {
        const itemValue = getTransformedLocationFilterData(String(appliedFilter.value));
        const filterType = getLocationFilterTypeFromItemType(itemValue.locationItemType);

        if (filterType === undefined) {
          return acc;
        }

        return [
          ...acc,
          {
            label: appliedFilter.label,
            value: appliedFilter.value,
            locationType: filterType,
            transformedLocationValue: itemValue,
          } as LocationFilterItem,
        ];
      }, [] as Array<LocationFilterItem>),
    [appliedFilters]
  );
  const dispatch = useAppDispatch();

  const setAppliedFilters = useCallback(
    (filterToApply: Array<LocationFilterItem>) => {
      dispatch(
        applyFilter({
          filterType: FilterType.LOCATION,
          filterData: filterToApply.map(filterItem => ({
            label: filterItem.label,
            value: filterItem.value,
          })),
        })
      );
    },
    [dispatch]
  );
  const isFilterVisible = useSelector(state => isFilterOpenedSelector(state, FilterType.LOCATION));
  const [locationView, setLocationView] = useState<LocationFilterViewType>(FILTER_DEFAULT_VIEW);
  const [locationSubView, setLocationSubView] = useState<LocationSubViewType | null>(
    FILTER_DEFAULT_SUB_VIEW
  );
  const [selectedGeofences, setSelectedGeofences] = useState<Array<LocationGeofenceFilterItem>>([]);
  const [selectedCountries, setSelectedCountries] = useState<Array<CountryLocationData>>(
    getSelectedCountriesFromAppliedFilters(transformedAppliedFilters)
  );
  const [selectedPointOfInterests, setSelectedPointOfInterests] = useState<
    Array<LocationPoiFilterItem>
  >([]);
  const [createdGeofences, setCreatedGeofences] = useState<CreatedGeofences>({});
  const [geofencesToAdjustRadiusZone, setGeofencesToAdjustRadiusZone] = useState<
    Array<LocationGeofenceFilterItem | LocationPoiFilterItem>
  >([]);

  const geofeaturesToDraw = useMemo<Array<GEOJSONData>>(() => {
    let geofences = selectedGeofences.map(geofence => geofence.transformedLocationValue.value);
    const pointOfInterests = selectedPointOfInterests.map(
      poi => poi.transformedLocationValue.value
    );

    if (isEditMode) {
      const polygonToEdit = createdGeofences[GeofenceType.POLYGON];
      const lineToEdit = createdGeofences[GeofenceType.LINE];

      geofences = geofences.filter(geofence => {
        if (polygonToEdit && geofence.properties.id === polygonToEdit.properties.id) {
          return false;
        }

        if (lineToEdit && geofence.properties.id === lineToEdit.properties.id) {
          return false;
        }

        return true;
      });
    }

    return [...geofences, ...pointOfInterests];
  }, [createdGeofences, isEditMode, selectedGeofences, selectedPointOfInterests]);

  useDrawGeofeaturesOnMap({
    geofencesList: geofeaturesToDraw,
    idSuffix: 'location-filter',
  });

  const onFiltersApply = () => {
    const mappedCountries: Array<LocationFilterItem> = selectedCountries.map(country => {
      const filterItemValue: LocationCountryFilterItemValue = {
        locationItemType: LocationFilterItemType.COUNTRY,
        value: {
          name: country.name,
          coordinates: country.coordinates!,
          id: country.id,
        },
      };

      return {
        value: getStringifiedLocationFilterData(filterItemValue),
        label: country.name,
        locationType: LocationFilterViewType.COUNTRY,
        transformedLocationValue: filterItemValue,
      };
    });

    const mappedPointOfInterests: Array<LocationPoiFilterItem> = selectedPointOfInterests.map(
      pointOfInterest => ({
        ...pointOfInterest,
        value: getStringifiedLocationFilterData(pointOfInterest.transformedLocationValue),
      })
    );

    setLocationSubView(FILTER_DEFAULT_SUB_VIEW);
    setAppliedFilters([...mappedCountries, ...selectedGeofences, ...mappedPointOfInterests]);
  };

  const onGeofenceAddClick = () => {
    setLocationSubView(LocationSubViewType.GEOFENCE_ADD);
  };

  const onPointOfInterestImportClick = () => {
    setLocationSubView(LocationSubViewType.POI_IMPORT);
  };

  const onGeofenceRemoveClick = (geofenceItem: LocationFilterItemValue) => {
    const stringifiedGeofenceItem = getStringifiedLocationFilterData(geofenceItem);

    setSelectedGeofences(
      selectedGeofences.filter(geofence => {
        const stringifiedGeofence = getStringifiedLocationFilterData(
          geofence.transformedLocationValue
        );

        return stringifiedGeofence !== stringifiedGeofenceItem;
      })
    );
  };

  const onPointOfInterestRemoveClick = (pointOfInterest: LocationFilterItemValue) => {
    const pointOfInterestId = (pointOfInterest as LocationPoiFilterItemValue).value.properties.id;

    setSelectedPointOfInterests(
      selectedPointOfInterests.filter(
        point => point.transformedLocationValue.value.properties.id !== pointOfInterestId
      )
    );
  };

  const onAppliedGeofenceRemoveClick = (filterItem: AppliedFilterDataItem) => {
    const locationAppliedFilterData = getTransformedLocationFilterData(String(filterItem.value));
    const filterType = getLocationFilterTypeFromItemType(
      locationAppliedFilterData.locationItemType
    );

    if (filterType === LocationFilterViewType.GEOFENCE) {
      onGeofenceRemoveClick(locationAppliedFilterData);
    }

    if (filterType === LocationFilterViewType.POI) {
      onPointOfInterestRemoveClick(locationAppliedFilterData);
    }

    setAppliedFilters(
      transformedAppliedFilters.filter(appliedFilter => appliedFilter.value !== filterItem.value)
    );
  };

  const onGeofenceFormCancel = () => {
    let newLocationSubView: LocationSubViewType | null = LocationSubViewType.GEOFENCE_ADD;

    if (isEditMode) {
      newLocationSubView = null;
      setIsEditMode(false);
      setCreatedGeofences({});
    }

    setLocationSubView(newLocationSubView);
  };

  const modalTitle = useMemo(() => {
    switch (locationSubView) {
      case LocationSubViewType.GEOFENCE_ADD: {
        return 'Geofence';
      }
      case LocationSubViewType.GEOFENCE_CREATE_RADIAL: {
        return 'Radial Geofence';
      }
      case LocationSubViewType.GEOFENCE_CREATE_LINE: {
        return 'Line Geofence';
      }
      case LocationSubViewType.GEOFENCE_CREATE_POLYGON: {
        return 'Polygon Geofence';
      }
      case LocationSubViewType.POI_IMPORT: {
        return 'Import POI';
      }
      default:
        return undefined;
    }
  }, [locationSubView]);

  const shouldShowBackButton = useMemo(() => locationSubView !== null, [locationSubView]);
  const shouldHideApplyButton = useMemo(() => locationSubView !== null, [locationSubView]);

  const onGeofenceClick = (geofenceType: GeofenceType) => {
    setLocationSubView(geofenceTypeToLocationSubViewType[geofenceType]);
  };

  const onFiltersBackClick = () => {
    let filterSubView: LocationSubViewType | null = FILTER_DEFAULT_SUB_VIEW;

    switch (locationSubView) {
      case LocationSubViewType.GEOFENCE_ADD: {
        filterSubView = FILTER_DEFAULT_SUB_VIEW;

        setCreatedGeofences({});

        break;
      }
      case LocationSubViewType.GEOFENCE_CREATE_RADIAL:
      case LocationSubViewType.GEOFENCE_CREATE_POLYGON:
      case LocationSubViewType.GEOFENCE_CREATE_LINE: {
        if (isEditMode) {
          filterSubView = null;
          setCreatedGeofences({});
        } else {
          filterSubView = LocationSubViewType.GEOFENCE_ADD;
        }
        break;
      }
      default:
        break;
    }

    setLocationSubView(filterSubView);
  };

  const onRadialGeofenceFormSubmit = (data: RadialGEOJSONData) => {
    let newLocationSubView: LocationSubViewType | null = LocationSubViewType.GEOFENCE_ADD;

    if (isEditMode) {
      newLocationSubView = null;

      const geofencesToSave = selectedGeofences.map(selectedGeofence => {
        if (
          selectedGeofence.transformedLocationValue.value.properties.id === data.properties.id &&
          selectedGeofence.transformedLocationValue.locationItemType ===
            LocationFilterItemType.GEOFENCE_RADIAL
        ) {
          const geofenceValue: LocationGeofenceFilterRadialItemValue = {
            locationItemType: LocationFilterItemType.GEOFENCE_RADIAL,
            value: data,
          };

          return {
            label: data.properties.name,
            locationType: LocationFilterViewType.GEOFENCE,
            transformedLocationValue: geofenceValue,
            value: getStringifiedLocationFilterData(geofenceValue),
            source: LocationFilterGeofenceSource.DIRECT_DRAW,
          } satisfies LocationGeofenceFilterItem;
        }

        return selectedGeofence;
      });

      setSelectedGeofences(geofencesToSave);
      setIsEditMode(false);
      setCreatedGeofences({});
    } else {
      setCreatedGeofences({
        ...createdGeofences,
        [GeofenceType.RADIAL]: data,
      });
    }

    setLocationSubView(newLocationSubView);
  };

  const onPolygonGeofenceFormSubmit = (data: PolygonGEOJSONData) => {
    let newLocationSubView: LocationSubViewType | null = LocationSubViewType.GEOFENCE_ADD;

    if (isEditMode) {
      newLocationSubView = null;

      const geofencesToSave = selectedGeofences.map(selectedGeofence => {
        if (
          selectedGeofence.transformedLocationValue.value.properties.id === data.properties.id &&
          selectedGeofence.transformedLocationValue.locationItemType ===
            LocationFilterItemType.GEOFENCE_POLYGON
        ) {
          const geofenceValue: LocationGeofenceFilterPolygonItemValue = {
            locationItemType: LocationFilterItemType.GEOFENCE_POLYGON,
            value: data,
          };

          return {
            label: data.properties.name,
            locationType: LocationFilterViewType.GEOFENCE,
            transformedLocationValue: geofenceValue,
            value: getStringifiedLocationFilterData(geofenceValue),
            source: LocationFilterGeofenceSource.DIRECT_DRAW,
          } satisfies LocationGeofenceFilterItem;
        }

        return selectedGeofence;
      });

      setSelectedGeofences(geofencesToSave);
      setIsEditMode(false);
      setCreatedGeofences({});
    } else {
      setCreatedGeofences({
        ...createdGeofences,
        [GeofenceType.POLYGON]: data,
      });
    }

    setLocationSubView(newLocationSubView);
  };

  const onLineGeofenceFormSubmit = (data: LineGEOJSONData) => {
    let newLocationSubView: LocationSubViewType | null = LocationSubViewType.GEOFENCE_ADD;

    if (isEditMode) {
      newLocationSubView = null;

      const geofencesToSave = selectedGeofences.map(selectedGeofence => {
        if (
          selectedGeofence.transformedLocationValue.value.properties.id === data.properties.id &&
          selectedGeofence.transformedLocationValue.locationItemType ===
            LocationFilterItemType.GEOFENCE_LINE
        ) {
          const geofenceValue: LocationGeofenceFilterLineItemValue = {
            locationItemType: LocationFilterItemType.GEOFENCE_LINE,
            value: data,
          };

          return {
            label: data.properties.name,
            locationType: LocationFilterViewType.GEOFENCE,
            transformedLocationValue: geofenceValue,
            value: getStringifiedLocationFilterData(geofenceValue),
            source: LocationFilterGeofenceSource.DIRECT_DRAW,
          } satisfies LocationGeofenceFilterItem;
        }

        return selectedGeofence;
      });

      setSelectedGeofences(geofencesToSave);
      setIsEditMode(false);
      setCreatedGeofences({});
    } else {
      setCreatedGeofences({
        ...createdGeofences,
        [GeofenceType.LINE]: data,
      });
    }

    setLocationSubView(newLocationSubView);
  };

  const onGeofencesSave = () => {
    const newGeofences: Array<LocationGeofenceFilterItem> = Object.entries(createdGeofences).reduce(
      (acc, [newGeofenceType, newGeofenceValue]) => {
        let locationItemType: LocationFilterItemType | undefined = undefined;

        if ((newGeofenceType as unknown as GeofenceType) == GeofenceType.RADIAL) {
          locationItemType = LocationFilterItemType.GEOFENCE_RADIAL;
        } else if ((newGeofenceType as unknown as GeofenceType) == GeofenceType.POLYGON) {
          locationItemType = LocationFilterItemType.GEOFENCE_POLYGON;
        } else if ((newGeofenceType as unknown as GeofenceType) == GeofenceType.LINE) {
          locationItemType = LocationFilterItemType.GEOFENCE_LINE;
        }

        if (locationItemType === undefined) {
          return acc;
        }

        // @ts-expect-error locationItemType is defined
        const geofenceValue: LocationGeofenceFilterItemValue = {
          locationItemType,
          value: newGeofenceValue,
        };

        return [
          ...acc,
          {
            label: newGeofenceValue.properties.name,
            locationType: LocationFilterViewType.GEOFENCE,
            transformedLocationValue: geofenceValue,
            value: getStringifiedLocationFilterData(geofenceValue),
            source: LocationFilterGeofenceSource.DIRECT_DRAW,
          },
        ];
      },
      [] as Array<LocationGeofenceFilterItem>
    );

    setSelectedGeofences([...selectedGeofences, ...newGeofences]);
    setCreatedGeofences({});
    setLocationSubView(FILTER_DEFAULT_SUB_VIEW);
    setIsEditMode(false);
  };

  const onGeofencesCancel = () => {
    setCreatedGeofences({});
    setLocationSubView(FILTER_DEFAULT_SUB_VIEW);
    setIsEditMode(false);
  };

  const onGeofenceSearchSelect = (suggestionItem: GeofeatureListSuggestionItem) => {
    const geofencesToAdd: Array<LocationGeofenceFilterItem> = [];
    const geofencesToAdjustRadius: Array<LocationGeofenceFilterItem> = [];

    if (suggestionItem.type === GeofeatureListSuggestionItemType.GEOFEATURE) {
      const locationItemType = getLocationFilterItemTypeFromShape(
        suggestionItem.item.geoJSONData.properties.shape
      );

      if (!locationItemType) {
        return;
      }

      const geofenceValue = {
        locationItemType,
        value: suggestionItem.item.geoJSONData,
      };

      const geofenceToAdd: LocationGeofenceFilterItem = {
        label: suggestionItem.item.name,
        locationType: LocationFilterViewType.GEOFENCE,
        // @ts-expect-error locationItemType is defined
        transformedLocationValue: geofenceValue,
        // @ts-expect-error locationItemType is defined
        value: getStringifiedLocationFilterData(geofenceValue),
        source: LocationFilterGeofenceSource.GEOFENCES_LIST,
      };

      if (locationItemType === LocationFilterItemType.GEOFENCE_LINE) {
        geofencesToAdjustRadius.push(geofenceToAdd);
      } else {
        geofencesToAdd.push(geofenceToAdd);
      }
    } else if (suggestionItem.type === GeofeatureListSuggestionItemType.FOLDER) {
      allGeofeaturesList
        .filter(
          geofeature =>
            geofeature.geoJSONData.properties.shape !== GEOJSONShapeType.POI &&
            geofeature.folderId === suggestionItem.item.id &&
            !selectedGeofences.some(
              ({ transformedLocationValue }) =>
                transformedLocationValue.value.properties.id ===
                geofeature.geoJSONData.properties.id
            )
        )
        .forEach(geofeature => {
          const locationItemType = getLocationFilterItemTypeFromShape(
            geofeature.geoJSONData.properties.shape
          );

          // @ts-expect-error types are corellated
          const geofenceValue: LocationGeofenceFilterItemValue = {
            locationItemType: locationItemType as Exclude<
              LocationFilterItemType,
              LocationFilterItemType.COUNTRY | LocationFilterItemType.POI
            >,
            value: geofeature.geoJSONData as
              | RadialGEOJSONData
              | PolygonGEOJSONData
              | LineGEOJSONData,
          };

          const geofenceToAdd: LocationGeofenceFilterItem = {
            label: geofeature.name,
            locationType: LocationFilterViewType.GEOFENCE,
            transformedLocationValue: geofenceValue,
            value: getStringifiedLocationFilterData(geofenceValue),
            source: LocationFilterGeofenceSource.GEOFENCES_LIST,
          };

          if (locationItemType === LocationFilterItemType.GEOFENCE_LINE) {
            geofencesToAdjustRadius.push(geofenceToAdd);
          } else {
            geofencesToAdd.push(geofenceToAdd);
          }
        });
    }

    setSelectedGeofences(prevState => [...prevState, ...geofencesToAdd]);

    if (geofencesToAdjustRadius.length > 0) {
      setGeofencesToAdjustRadiusZone(geofencesToAdjustRadius);
      setLocationSubView(LocationSubViewType.RADIUS_SELECT);
    }
  };

  const onPointOfInterestSearchSelect = (suggestionItem: GeofeatureListSuggestionItem) => {
    const geofencesToAdd: Array<LocationPoiFilterItem> = [];

    if (suggestionItem.type === GeofeatureListSuggestionItemType.GEOFEATURE) {
      const locationItemType = getLocationFilterItemTypeFromShape(
        suggestionItem.item.geoJSONData.properties.shape
      );

      if (!locationItemType) {
        return;
      }

      const geofenceValue = {
        locationItemType,
        value: suggestionItem.item.geoJSONData,
      };

      geofencesToAdd.push({
        label: suggestionItem.item.name,
        locationType: LocationFilterViewType.POI,
        // @ts-expect-error locationItemType is defined
        transformedLocationValue: geofenceValue,
        // @ts-expect-error locationItemType is defined
        value: getStringifiedLocationFilterData(geofenceValue),
      });
    } else if (suggestionItem.type === GeofeatureListSuggestionItemType.FOLDER) {
      const geofeaturesInFoldersallGeofeaturesList = allGeofeaturesList
        .filter(
          geofeature =>
            geofeature.geoJSONData.properties.shape === GEOJSONShapeType.POI &&
            geofeature.folderId === suggestionItem.item.id &&
            !selectedPointOfInterests.some(
              ({ transformedLocationValue }) =>
                transformedLocationValue.value.properties.id ===
                geofeature.geoJSONData.properties.id
            )
        )
        .map<LocationPoiFilterItem>(geofeature => {
          const locationItemType = getLocationFilterItemTypeFromShape(
            geofeature.geoJSONData.properties.shape
          );

          const geofenceValue: LocationPoiFilterItemValue = {
            locationItemType: locationItemType as LocationFilterItemType.POI,
            value: geofeature.geoJSONData as PointOfInterestGEOJSONData,
          };

          return {
            label: geofeature.name,
            locationType: LocationFilterViewType.POI,
            transformedLocationValue: geofenceValue,
            value: getStringifiedLocationFilterData(geofenceValue),
            source: LocationFilterGeofenceSource.GEOFENCES_LIST,
          };
        });

      geofencesToAdd.push(...geofeaturesInFoldersallGeofeaturesList);
    }

    setGeofencesToAdjustRadiusZone(geofencesToAdd);
    setLocationSubView(LocationSubViewType.RADIUS_SELECT);
  };

  const onPointOfInterestImport = async (
    pointsOfInterests: GEOJSONData | GEOJSONCollectionData | string
  ) => {
    let pointsOfInterestsToIterate: Array<PointOfInterestGEOJSONData> = [];
    let pointsOfInterestsData = pointsOfInterests;

    if (typeof pointsOfInterestsData === 'string') {
      pointsOfInterestsData =
        await transformPointsOfInterestFromTextToGeoJSON(pointsOfInterestsData);
    }

    if (pointsOfInterestsData.type === 'FeatureCollection') {
      pointsOfInterestsToIterate =
        pointsOfInterestsData.features as Array<PointOfInterestGEOJSONData>;
    } else {
      pointsOfInterestsToIterate = [pointsOfInterestsData as PointOfInterestGEOJSONData];
    }

    const pointsOfInterestsToAdd: Array<LocationPoiFilterItem> = pointsOfInterestsToIterate.map(
      pointOfInterest => {
        const poiValue: LocationPoiFilterItemValue = {
          locationItemType: LocationFilterItemType.POI,
          value: pointOfInterest,
        };

        return {
          label: pointOfInterest.properties.name,
          locationType: LocationFilterViewType.POI,
          transformedLocationValue: poiValue,
          value: getStringifiedLocationFilterData(poiValue),
        };
      }
    );

    setGeofencesToAdjustRadiusZone(pointsOfInterestsToAdd);

    setLocationSubView(LocationSubViewType.RADIUS_SELECT);
  };

  const onGeofenceItemClick = (geofence: LocationGeofenceFilterItem) => {
    if (geofence.source === LocationFilterGeofenceSource.GEOFENCES_LIST) {
      return;
    }

    let geofenceType: GeofenceType | undefined = undefined;
    let internalLocationSubView: LocationSubViewType | null = null;

    if (
      geofence.transformedLocationValue.locationItemType === LocationFilterItemType.GEOFENCE_RADIAL
    ) {
      geofenceType = GeofenceType.RADIAL;
      internalLocationSubView = LocationSubViewType.GEOFENCE_CREATE_RADIAL;
    } else if (
      geofence.transformedLocationValue.locationItemType === LocationFilterItemType.GEOFENCE_POLYGON
    ) {
      geofenceType = GeofenceType.POLYGON;
      internalLocationSubView = LocationSubViewType.GEOFENCE_CREATE_POLYGON;
    } else if (
      geofence.transformedLocationValue.locationItemType === LocationFilterItemType.GEOFENCE_LINE
    ) {
      geofenceType = GeofenceType.LINE;
      internalLocationSubView = LocationSubViewType.GEOFENCE_CREATE_LINE;
    }

    if (geofenceType === undefined) {
      return;
    }

    setCreatedGeofences({
      [geofenceType]: geofence.transformedLocationValue.value,
    });
    setLocationSubView(internalLocationSubView);
    setIsEditMode(true);
  };

  const onAdjustRadiusZoneSubmit = (
    adjustedGeofences: Array<LocationGeofenceFilterItem | LocationPoiFilterItem>
  ) => {
    const adjustedPointOfInterests: Array<LocationPoiFilterItem> = [];
    const adjustedLineGeofences: Array<LocationGeofenceFilterItem> = [];

    adjustedGeofences.forEach(adjustedGeofence => {
      if (
        adjustedGeofence.transformedLocationValue.locationItemType === LocationFilterItemType.POI
      ) {
        adjustedPointOfInterests.push(adjustedGeofence as LocationPoiFilterItem);
      }

      if (
        adjustedGeofence.transformedLocationValue.locationItemType ===
        LocationFilterItemType.GEOFENCE_LINE
      ) {
        adjustedLineGeofences.push(adjustedGeofence as LocationGeofenceFilterItem);
      }
    });

    setGeofencesToAdjustRadiusZone([]);
    setLocationSubView(FILTER_DEFAULT_SUB_VIEW);
    setSelectedPointOfInterests([...selectedPointOfInterests, ...adjustedPointOfInterests]);
    setSelectedGeofences([...selectedGeofences, ...adjustedLineGeofences]);
  };

  const onAdjustRadiusZoneCancel = () => {
    setGeofencesToAdjustRadiusZone([]);
    setLocationSubView(FILTER_DEFAULT_SUB_VIEW);
  };

  const appliedCountries = useMemo<AppliedFilterData>(() => {
    return transformedAppliedFilters.reduce((acc, filterItem) => {
      if (filterItem.locationType === LocationFilterViewType.COUNTRY) {
        return [
          ...acc,
          {
            label: filterItem.label,
            value: filterItem.transformedLocationValue.value.id,
          },
        ];
      }

      return acc;
    }, [] as AppliedFilterData);
  }, [transformedAppliedFilters]);

  useEffect(() => {
    if (!isFilterVisible && locationSubView !== null) {
      setLocationSubView(FILTER_DEFAULT_SUB_VIEW);
      setCreatedGeofences({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilterVisible]);

  useEffect(() => {
    if (appliedFilters.length === 0) {
      setLocationView(FILTER_DEFAULT_VIEW);
      setLocationSubView(FILTER_DEFAULT_SUB_VIEW);

      setSelectedGeofences([]);
      setSelectedCountries([]);
      setSelectedPointOfInterests([]);
      setCreatedGeofences({});
    } else {
      const geofencesToSelect: Array<LocationGeofenceFilterItem> = [];
      const pointOfInterestsToSelect: Array<LocationPoiFilterItem> = [];

      transformedAppliedFilters.forEach(appliedFilter => {
        if (appliedFilter.locationType === LocationFilterViewType.GEOFENCE) {
          geofencesToSelect.push(appliedFilter);
        }

        if (appliedFilter.locationType === LocationFilterViewType.POI) {
          pointOfInterestsToSelect.push(appliedFilter);
        }
      });

      setSelectedGeofences(geofencesToSelect);
      setSelectedPointOfInterests(pointOfInterestsToSelect);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedFilters]);

  return (
    <FilterContainer
      filterType={FilterType.LOCATION}
      filterName="Location"
      onFiltersApply={onFiltersApply}
      shouldShowBackButton={shouldShowBackButton}
      shouldHideApplyButton={shouldHideApplyButton}
      modalTitle={modalTitle}
      onFiltersBackClick={onFiltersBackClick}
      onAppliedFilterRemove={onAppliedGeofenceRemoveClick}
    >
      <FilterLocationContainer>
        {locationSubView === null && (
          <>
            <FilterTypeContainer>
              <MultiButton
                isSmall
                active={locationView}
                onChange={setLocationView}
                tabsData={locationTabs}
              />
            </FilterTypeContainer>
            {locationView === LocationFilterViewType.COUNTRY && (
              <CountryView
                onSelectCountries={setSelectedCountries}
                appliedFilters={appliedCountries}
              />
            )}
            {locationView === LocationFilterViewType.GEOFENCE && (
              <GeofencesList
                geofencesList={selectedGeofences}
                onGeofenceAddClick={onGeofenceAddClick}
                onGeofenceRemoveClick={onGeofenceRemoveClick}
                onGeofenceSearchSelect={onGeofenceSearchSelect}
                onGeofenceItemClick={onGeofenceItemClick}
              />
            )}
            {locationView === LocationFilterViewType.POI && (
              <PointOfInterestsList
                pointOfInterestsList={selectedPointOfInterests}
                onPointOfInterestImportClick={onPointOfInterestImportClick}
                onPointOfInterestRemoveClick={onPointOfInterestRemoveClick}
                onPointOfInterestSearchSelect={onPointOfInterestSearchSelect}
              />
            )}
          </>
        )}
        {locationSubView === LocationSubViewType.GEOFENCE_ADD && (
          <CreateGeofenceView
            createdGeofences={createdGeofences}
            isEditMode={isEditMode}
            onGeofenceClick={onGeofenceClick}
            onGeofencesSave={onGeofencesSave}
            onGeofenceCancel={onGeofencesCancel}
          />
        )}
        {locationSubView === LocationSubViewType.GEOFENCE_CREATE_RADIAL && (
          <CreateRadialGeofence
            withoutFolder
            initialData={createdGeofences[GeofenceType.RADIAL]}
            onFormSubmit={onRadialGeofenceFormSubmit}
            onFormCancel={onGeofenceFormCancel}
          />
        )}
        {locationSubView === LocationSubViewType.GEOFENCE_CREATE_POLYGON && (
          <CreatePolygonGeofence
            withoutFolder
            initialData={createdGeofences[GeofenceType.POLYGON]}
            onFormSubmit={onPolygonGeofenceFormSubmit}
            onFormCancel={onGeofenceFormCancel}
          />
        )}
        {locationSubView === LocationSubViewType.GEOFENCE_CREATE_LINE && (
          <CreateLineGeofence
            withoutFolder
            initialData={createdGeofences[GeofenceType.LINE]}
            onFormSubmit={onLineGeofenceFormSubmit}
            onFormCancel={onGeofenceFormCancel}
          />
        )}
        {locationSubView === LocationSubViewType.POI_IMPORT && (
          <PointOfInterestImport onSubmit={onPointOfInterestImport} />
        )}
        {locationSubView === LocationSubViewType.RADIUS_SELECT && (
          <AdjustGeofenceRadius
            geofencesList={geofencesToAdjustRadiusZone}
            onSubmit={onAdjustRadiusZoneSubmit}
            onCancel={onAdjustRadiusZoneCancel}
          />
        )}
      </FilterLocationContainer>
    </FilterContainer>
  );
};
