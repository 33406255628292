import styled from 'styled-components';

import { ThemeVariant } from '@/core/interfaces/common';

import { getVariant } from '@/themes/typographyUtilis';

export const InputContainer = styled.div`
  position: relative;
`;

export const StyledTextarea = styled.textarea<{
  withError?: boolean;
}>`
  width: 100%;
  border: 1px solid
    ${({ withError, theme }) =>
      withError
        ? theme.colors.warning
        : theme.theme === ThemeVariant.DARK
          ? theme.colors.background.light
          : theme.colors.border};
  border-radius: 4px;
  padding: 12px 19px;
  background: ${({ theme }) => theme.colors.background.light};
  color: ${({ theme }) => theme.colors.primaryText};
  ${getVariant({ variant: 'body1-normal' })};
  text-align: left;

  &:focus {
    outline: none;
    border-color: ${({ theme }) =>
      theme.theme === ThemeVariant.DARK ? theme.colors.background.light : theme.colors.active};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.secondaryText};
  }
`;
