import { BaseGEOJSONProperties, GEOJSONData, GEOJSONShapeType } from '@/core/interfaces/geojsons';

import {
  FolderGeofeatureItem,
  GeofeatureFolderItem,
  GeofeatureItem,
} from '@/features/Geofeatures/interfaces';

export const transformGeofeaturFromFolder = (
  geofeatures: Array<FolderGeofeatureItem>,
  folderId: GeofeatureFolderItem['id']
): Array<GeofeatureItem> => {
  return geofeatures.map(geofeature => {
    const baseProperties: BaseGEOJSONProperties = {
      id: geofeature.uuid,
      color: geofeature.color,
      description: geofeature.description,
      folder: folderId,
      icon: geofeature.icon,
      name: geofeature.name,
      shape: geofeature.shape,
      tags: geofeature.tags,
    };

    let geofeatureGeoJSON: GEOJSONData | undefined = undefined;

    if (geofeature.shape === GEOJSONShapeType.CIRCLE && geofeature.polygon) {
      geofeatureGeoJSON = {
        type: 'Feature',
        geometry: geofeature.polygon,
        properties: {
          ...baseProperties,
          shape: GEOJSONShapeType.CIRCLE,
          center: {
            lat: geofeature.lat,
            lng: geofeature.long,
          },
          address: geofeature.address,
          radius: geofeature.radius,
          radiusUnit: geofeature.radiusUnit,
        },
      };
    } else if (geofeature.shape === GEOJSONShapeType.POLYGON && geofeature.polygon) {
      geofeatureGeoJSON = {
        type: 'Feature',
        geometry: geofeature.polygon,
        properties: {
          ...baseProperties,
          shape: GEOJSONShapeType.POLYGON,
        },
      };
    } else if (geofeature.shape === GEOJSONShapeType.LINE && geofeature.line) {
      geofeatureGeoJSON = {
        type: 'Feature',
        geometry: geofeature.line,
        properties: {
          ...baseProperties,
          shape: GEOJSONShapeType.LINE,
          radius: geofeature.radius,
          radiusUnit: geofeature.radiusUnit,
        },
      };
    } else if (geofeature.shape === GEOJSONShapeType.POI && geofeature.point) {
      geofeatureGeoJSON = {
        type: 'Feature',
        geometry: geofeature.point,
        properties: {
          ...baseProperties,
          shape: GEOJSONShapeType.POI,
          address: geofeature.address,
          zoneRadius: geofeature.radius,
          zoneRadiusUnit: geofeature.radiusUnit,
        },
      };
    }

    if (!geofeatureGeoJSON) {
      throw new Error('Invalid geofeatureGeoJSON');
    }

    return {
      id: geofeature.uuid,
      color: geofeature.color,
      icon: geofeature.icon,
      name: geofeature.name,
      description: geofeature.description,
      folderId,
      tags: geofeature.tags,
      visibleOnMap: geofeature.display,
      geoJSONData: geofeatureGeoJSON,
    } satisfies GeofeatureItem;
  });
};
