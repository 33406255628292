import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { MapEventType } from 'maplibre-gl';
import { useTheme } from 'styled-components';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { colorPickerColors } from '@/core/constants/colors';
import { MapContext } from '@/core/context/MapContext';
import { GEOJSONShapeType } from '@/core/interfaces/geojsons';
import { PointCoordinates } from '@/core/interfaces/common';
import { useMapStyleName } from '@/core/hooks/useMapStyleName';
import { IconPickerIcons } from '@/core/constants/icons';
import { SelectOptionType } from '@/core/components/Select/SelectComponent.types';
import { disableDrawingOnMap, enableDrawingOnMap } from '@/core/store/reducers/config';
import { useAppDispatch } from '@/core/store/store';

import { getFoldersListSelector } from '@/features/Geofeatures/store';

import {
  drawPointOfInterestGeofeature,
  getIconForTheme,
  getPointOfInterestDataName,
  getPointOfInterestFillLayerName,
  getPointOfInterestIconLayerName,
  removePointOfInterestGeofeature,
} from '@/utils/map/drawPointOfInterest';

import {
  CreatePointOfInterestProps,
  PointOfInterestGeofenceFormData,
} from './CreatePointOfInterest.types';
import { PointOfInterestGeofenceForm } from './components/PointOfInterestGeofenceForm';

const schema = yup.object().shape({
  center: yup.object().shape({
    lat: yup.number(),
    lng: yup.number(),
  }),
  name: yup.string().required(),
  folder: yup.object().nullable(),
  description: yup.string(),
  tags: yup.array().of(
    yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    })
  ),
  color: yup.string().required(),
  icon: yup.string().required(),
});

export const CreatePointOfInterest = ({
  initialData,
  defaultFolder,
  onFormSubmit,
  onFormCancel,
}: CreatePointOfInterestProps) => {
  const { mapRef } = useContext(MapContext);
  const poiCenter = useRef<PointCoordinates | null>(initialData?.geometry.coordinates || null);
  const poiColor = useRef(initialData?.properties.color || colorPickerColors.color1);
  const poiIcon = useRef<IconPickerIcons>(initialData?.properties.icon || 'Flag');
  const [dataId] = useState(initialData?.properties.id || uuidv4());
  const shouldDraw = useRef(true);
  const geofeaturesFoldersList = useSelector(getFoldersListSelector);
  const { theme } = useTheme();

  const dispatch = useAppDispatch();

  const defaultFormFolder = useMemo<SelectOptionType<string | number> | undefined>(() => {
    let folderOption = defaultFolder;

    if (initialData?.properties.folder) {
      const folder = geofeaturesFoldersList.find(f => f.id === initialData.properties.folder);

      if (folder) {
        folderOption = {
          label: folder.name,
          value: folder.id,
        };
      }
    } else if (!defaultFolder && geofeaturesFoldersList.length) {
      const firstFolder = geofeaturesFoldersList[0];

      folderOption = {
        label: firstFolder.name,
        value: firstFolder.id,
      };
    }

    return folderOption;
  }, [defaultFolder, geofeaturesFoldersList, initialData]);

  const formMethods = useForm<PointOfInterestGeofenceFormData>({
    defaultValues: {
      color: poiColor.current,
      icon: poiIcon.current,
      name: initialData?.properties.name || '',
      description: initialData?.properties.description || '',
      folder: defaultFormFolder,
      tags:
        initialData?.properties.tags.map(tag => ({
          label: tag,
          value: tag,
        })) || [],
      center: poiCenter.current
        ? {
            name:
              initialData?.properties.address || `${poiCenter.current[1]}, ${poiCenter.current[0]}`, // 'lat, lng
            coordinates: {
              lat: poiCenter.current?.[1] ?? 0,
              lng: poiCenter.current?.[0] ?? 0,
            },
          }
        : undefined,
    },
    resolver: yupResolver(schema),
  });

  const { setValue, watch, formState } = formMethods;

  const polygonColorWatch = watch('color');
  const radiusCenterWatch = watch('center');
  const iconWatch = watch('icon');

  const isFormSubmitting = formState.isSubmitting;

  const poiDataName = useMemo(() => getPointOfInterestDataName(dataId), [dataId]);
  const poiFillLayerName = useMemo(() => getPointOfInterestFillLayerName(dataId), [dataId]);
  const poiIconLayerName = useMemo(() => getPointOfInterestIconLayerName(dataId), [dataId]);

  const mapStyleName = useMapStyleName();

  const drawPoI = ({
    center = poiCenter.current,
    color = poiColor.current,
    icon = poiIcon.current,
  }: {
    center?: PointCoordinates | null;
    color?: string;
    icon?: IconPickerIcons;
  }) => {
    if (shouldDraw.current) {
      drawPointOfInterestGeofeature(mapRef, {
        center,
        color,
        icon: getIconForTheme(icon, theme === 'dark'),
        poiDataName,
        poiFillLayerName,
        poiIconLayerName,
      });
    }
  };

  const onCenterChange = (coordinates: PointCoordinates) => {
    poiCenter.current = coordinates;

    if (!poiCenter.current || !mapRef) {
      return;
    }

    drawPoI({ center: poiCenter.current });
  };

  const drawCircleOnMouseUp = (e: MapEventType['mouseup']) => {
    if (shouldDraw.current) {
      const center = [e.lngLat.lng, e.lngLat.lat] as PointCoordinates;

      poiCenter.current = center;

      setValue('center', {
        name: `${poiCenter.current[1]}, ${poiCenter.current[0]}`, // 'lat, lng
        coordinates: {
          lat: poiCenter.current[1],
          lng: poiCenter.current[0],
        },
      });

      drawPoI({ center });
    }
  };

  const onColorChange = (color: string) => {
    poiColor.current = color;

    if (!poiColor.current || !mapRef) {
      return;
    }

    drawPoI({ color: poiColor.current });
  };

  const onIconChange = (icon: IconPickerIcons) => {
    poiIcon.current = icon;

    if (!poiIcon.current || !mapRef) {
      return;
    }

    drawPoI({ icon: poiIcon.current });
  };

  useEffect(() => {
    drawPoI({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapStyleName, theme]);

  useEffect(() => {
    onColorChange(polygonColorWatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polygonColorWatch]);

  useEffect(() => {
    if (radiusCenterWatch?.coordinates) {
      onCenterChange([radiusCenterWatch.coordinates.lng, radiusCenterWatch.coordinates.lat]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radiusCenterWatch]);

  useEffect(() => {
    onIconChange(iconWatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iconWatch]);

  const onMapMoveStart = () => {
    shouldDraw.current = false;
  };
  const onMapMoveEnd = () => {
    shouldDraw.current = true;
  };

  useEffect(() => {
    const map = mapRef?.getMap();

    if (!map) {
      return;
    }

    dispatch(enableDrawingOnMap('poi'));

    map.on('movestart', onMapMoveStart);
    map.on('mouseup', drawCircleOnMouseUp);
    map.on('moveend', onMapMoveEnd);

    return () => {
      map.off('movestart', onMapMoveStart);
      map.off('mouseup', drawCircleOnMouseUp);
      map.off('moveend', onMapMoveEnd);

      dispatch(disableDrawingOnMap());

      removePointOfInterestGeofeature(mapRef, {
        poiDataName,
        poiFillLayerName,
        poiIconLayerName,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormSubmit = useCallback(
    async (formData: PointOfInterestGeofenceFormData) => {
      const map = mapRef?.getMap();

      const circleSource = map?.getSource(poiDataName)?.serialize();

      if (!circleSource) {
        return;
      }

      await onFormSubmit({
        ...circleSource.data,
        properties: {
          ...circleSource.data.properties,
          id: dataId,
          shape: GEOJSONShapeType.POI,
          color: formData.color,
          icon: formData.icon,
          name: formData.name,
          description: formData.description,
          folder: formData.folder?.value,
          tags: formData.tags?.map(tag => tag.value) || [],
          address: formData.center.name,
        },
      });
    },
    [dataId, mapRef, onFormSubmit, poiDataName]
  );

  return (
    <FormProvider {...formMethods}>
      <PointOfInterestGeofenceForm
        isSubmitting={isFormSubmitting}
        onSubmit={handleFormSubmit}
        onCancel={onFormCancel}
      />
    </FormProvider>
  );
};
