import styled from 'styled-components';

import { getVariant } from '@/themes/typographyUtilis';

export const CoworkersList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 16px;
  padding: 0;
  list-style: none;
`;

export const CoworkerItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  padding: 10px 30px;
`;

export const RoleBadge = styled.span`
  border-radius: 4px;
  padding: 4px 11px;
  background-color: ${({ theme }) => theme.colors.active};
  ${getVariant({ variant: 'body2-normal' })}
`;

export const DeleteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;
