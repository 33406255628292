import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '@/features/Reports/api';
import { ReportResponseItem, ReportsListQueryParams } from '@/features/Reports/interfaces';

import { getActionPrefix } from '@/utils/helpers';

import { appendHorizonReports, appendLookoutReports } from './slices';

const actionPrefix = getActionPrefix('reports');

export const getHorizonReportsList = createAsyncThunk<
  Array<ReportResponseItem>,
  ReportsListQueryParams
>(`${actionPrefix}/getHorizonReportsList`, async (filtersQueryParams, { signal, dispatch }) => {
  let pageNumber = 1;
  let shouldFetch = true;
  const reportsList: Array<ReportResponseItem> = [];
  const shouldFetchAllPages = !filtersQueryParams.page;

  // We have to fetch all pages in one go as we don't have pagination views on the map
  do {
    try {
      const { data } = await api.getHorizonReportsList(
        {
          page: pageNumber,
          ...filtersQueryParams,
        },
        signal
      );

      if (shouldFetchAllPages) {
        dispatch(appendHorizonReports(data.results));
      }

      reportsList.push(...data.results);

      pageNumber += 1;
      shouldFetch = !!data.next;
    } catch {
      shouldFetch = false;
    }
  } while (shouldFetch && shouldFetchAllPages);

  return reportsList;
});

export const getLookoutReportsList = createAsyncThunk<
  Array<ReportResponseItem>,
  ReportsListQueryParams
>(`${actionPrefix}/getLookoutReportsList`, async (filtersQueryParams, { signal, dispatch }) => {
  let pageNumber = 1;
  let shouldFetch = true;
  const reportsList: Array<ReportResponseItem> = [];
  const shouldFetchAllPages = !filtersQueryParams.page;

  // We have to fetch all pages in one go as we don't have pagination views on the map
  do {
    try {
      const { data } = await api.getLookoutReportsList(
        {
          page: pageNumber,
          ...filtersQueryParams,
        },
        signal
      );

      if (shouldFetchAllPages) {
        dispatch(appendLookoutReports(data.results));
      }

      reportsList.push(...data.results);

      pageNumber += 1;
      shouldFetch = !!data.next;
    } catch {
      shouldFetch = false;
    }
  } while (shouldFetch && shouldFetchAllPages);

  return reportsList;
});
