import {
  HeatmapLayer,
  CircleLayer,
  SymbolLayer,
  FillLayer,
  LineLayer,
  BackgroundLayer,
} from 'react-map-gl/maplibre';
import { DataDrivenPropertyValueSpecification } from 'maplibre-gl';

import naturalDisaster from 'public/assets/map/natural-disaster.png';
import cybersecurity from 'public/assets/map/cybersecurity.png';
import economy from 'public/assets/map/economy.png';
import politics from 'public/assets/map/politics.png';
import safety from 'public/assets/map/safety.png';
import sector from 'public/assets/map/sector.png';
import security from 'public/assets/map/security.png';
import travel from 'public/assets/map/travel.png';

import { ReportImpact, ThemeVariant } from '@/core/interfaces/common';

import { lightTheme, darkTheme } from '@/themes/main';

export const MAP_ICONS = {
  cybersecurity,
  economy,
  politics,
  safety,
  sector,
  security,
  travel,
  naturalDisaster,
};

export const getCategoryIconNameFromReportItem = (
  category: string
): keyof typeof MAP_ICONS | null => {
  switch (category) {
    case 'Sector': {
      return 'sector';
    }
    case 'Natural disaster': {
      return 'naturalDisaster';
    }
    case 'Security': {
      return 'security';
    }
    case 'Politics': {
      return 'politics';
    }
    case 'Cybersecurity': {
      return 'cybersecurity';
    }
    case 'Economy': {
      return 'economy';
    }
    case 'Safety': {
      return 'safety';
    }
    case 'Travel': {
      return 'travel';
    }
    default: {
      return null;
    }
  }
};

export const MAP_STYLE_DARK =
  'https://api.maptiler.com/maps/52385d30-90a8-45ab-a2f5-c56e0b43d741/style.json';
export const MAP_STYLE_LIGHT =
  'https://api.maptiler.com/maps/96f066ea-c18b-4060-a321-f7a8856e5f16/style.json';
export const MAP_STYLE_SATELITE =
  'https://api.maptiler.com/maps/8fda6dff-c9a3-41bf-a893-7f2761788a5c/style.json';
export const MAP_STYLE_STREET =
  'https://api.maptiler.com/maps/9114521c-0b1e-4079-9ec4-995a5d3624ed/style.json';

export const DEFAULT_ZOOM = 0.8;
export const FLY_TO_LOCATION_ZOOM = 10;
export const CLUSTER_MAX_ZOOM = 14;
export const POINT_OF_INTEREST_ZOOM = 18;

export const DEFAULT_VIEWSTATE = {
  longitude: 0,
  latitude: 30,
  zoom: DEFAULT_ZOOM,
};

// LAYER STYLES:
export enum MapCurrentLayer {
  PINS,
  HEATMAP,
  IMPACT_MAP,
}

// LAYER IDS:
export const PINS_LAYER_ID = 'pins-layer';
export const HEATMAP_LAYER_ID = 'heatmap-layer';
export const NEW_REPORTS_LAYER_ID = 'new-reports-layer';
export const CLUSTERS_LAYER_ID = 'clusters-layer';
export const CLUSTER_COUNT_LAYER_ID = 'cluster-count-layer';
export const IMPACT_LAYER_ID = 'impact-layer';
export const COUNTRIES_LAYER_ID = 'countries-layer';
export const COUNTRIES_BORDER_LAYER_ID = 'countries-border-layer';
export const WORLD_BACKGROUND_LAYER_ID = 'world-background-layer';

// LAYER STYLES:
export const pinsLayer: SymbolLayer = {
  id: PINS_LAYER_ID,
  type: 'symbol',
  source: 'my-data',
  filter: ['!', ['has', 'point_count']],
  layout: {
    'icon-image': ['get', 'icon'],
    'icon-anchor': 'bottom',
  },
};

export const clustersLayer: CircleLayer = {
  id: CLUSTERS_LAYER_ID,
  type: 'circle',
  source: 'my-data',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': 'rgba(98, 121, 157, 0.45)',
    'circle-radius': 50,
  },
};

export const clusterCountLayer: SymbolLayer = {
  id: CLUSTER_COUNT_LAYER_ID,
  type: 'symbol',
  source: 'my-data',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['Inter', 'Raleway', 'sans-serif'],
    'text-size': 24,
  },
  paint: {
    'text-color': '#ffffff',
  },
};

export const heatmapLayer: HeatmapLayer = {
  id: HEATMAP_LAYER_ID,
  source: 'heatmap-source',
  type: 'heatmap',
  maxzoom: 20,
  paint: {
    'heatmap-weight': ['interpolate', ['linear'], ['get', 'reports'], 0, 0, 20000, 1],
    'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1, 12, 3],
    'heatmap-color': [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
      0,
      'rgba(0, 0, 0, 0)',
      0.1,
      'rgba(58, 61, 194)',
      0.2,
      'rgba(51, 105, 239)',
      0.3,
      'rgba(51, 183, 239)',
      0.4,
      'rgba(51, 239, 183)',
      0.5,
      'rgba(51, 239, 183)',
      0.6,
      'rgba(242, 228, 99)',
      0.7,
      'rgba(242, 185, 99)',
      0.8,
      'rgba(242, 151, 99)',
      0.9,
      'rgba(242, 99, 99)',
      1,
      'rgba(229, 71, 71)',
    ],

    'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, 9, 20],
    'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 7, 1, 18, 0],
  },
};

export function getImpactLayer(theme: ThemeVariant): CircleLayer {
  const currentTheme = theme === ThemeVariant.LIGHT ? lightTheme : darkTheme;

  const impactColors = {
    [ReportImpact.LOW]: currentTheme.colors.impact[ReportImpact.LOW].backgroundColor,
    [ReportImpact.MEDIUM]: currentTheme.colors.impact[ReportImpact.MEDIUM].backgroundColor,
    [ReportImpact.HIGH]: currentTheme.colors.impact[ReportImpact.HIGH].backgroundColor,
    [ReportImpact.EXTREME]: currentTheme.colors.impact[ReportImpact.EXTREME].backgroundColor,
  };

  const matchExpression = ['match', ['get', 'impact']];

  for (const [key, value] of Object.entries(impactColors)) {
    matchExpression.push(key, value);
  }
  matchExpression.push(currentTheme.colors.impact[ReportImpact.LOW].backgroundColor); // fallback color

  return {
    id: IMPACT_LAYER_ID,
    type: 'circle',
    source: 'impact-source',
    paint: {
      'circle-color': matchExpression as DataDrivenPropertyValueSpecification<string> | undefined,
      'circle-radius': [
        'match',
        ['get', 'impact'],
        'Low',
        5,
        'Medium',
        10,
        'High',
        15,
        'Extreme',
        20,
        0, // fallback radius
      ],
      'circle-opacity': ['case', ['!', ['has', 'impact']], 0, 1],
    },
  };
}

const newReportDetectionTime = 1000 * 60 * 1; // 1 minute

export const getNewReportsLayer = (datetime: number): SymbolLayer => ({
  id: NEW_REPORTS_LAYER_ID,
  type: 'symbol',
  source: 'points',
  filter: [
    'all',
    ['>=', 'publicationDate', datetime - newReportDetectionTime],
    ['==', 'wasOpened', false],
  ],
  layout: {
    'icon-image': [
      'concat',
      'pulsing-dot-',
      ['get', 'impact'],
      '-',
      ['to-string', ['get', 'breaking']],
      '-',
      ['get', 'icon'],
    ],
  },
});

export const getWorldBackgroundLayer = (theme: string): BackgroundLayer => {
  const currentTheme = theme === ThemeVariant.LIGHT ? lightTheme : darkTheme;

  return {
    id: WORLD_BACKGROUND_LAYER_ID,
    type: 'background',
    paint: {
      'background-color': currentTheme.colors.mapBackground,
    },
  };
};

export const getImpactCountriesLayer = (theme: string): FillLayer => {
  const currentTheme = theme === ThemeVariant.LIGHT ? lightTheme : darkTheme;

  return {
    id: COUNTRIES_LAYER_ID,
    type: 'fill',
    source: 'countries',
    layout: {},
    paint: {
      'fill-color': [
        'match',
        ['get', 'impact'],
        [ReportImpact.LOW],
        currentTheme.colors.impact[ReportImpact.LOW].backgroundColor,
        [ReportImpact.MEDIUM],
        currentTheme.colors.impact[ReportImpact.MEDIUM].backgroundColor,
        [ReportImpact.HIGH],
        currentTheme.colors.impact[ReportImpact.HIGH].backgroundColor,
        [ReportImpact.EXTREME],
        currentTheme.colors.impact[ReportImpact.EXTREME].backgroundColor,
        'transparent', // fallback color
      ],
    },
  };
};

export const countriesBorderLayer: LineLayer = {
  id: COUNTRIES_BORDER_LAYER_ID,
  type: 'line',
  source: 'countries',
  layout: {},
  paint: {
    'line-color': '#ffffff',
    'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 3, 1],
  },
};
