import {
  LocationFilterItemType,
  LocationFilterItemValue,
  LocationFilterViewType,
} from '@/core/components/MapFilters/LocationFilter/LocationFilter.types';
import { GEOJSONShapeType } from '@/core/interfaces/geojsons';

const fromBase64 = (base64: string) => {
  const binString = atob(base64);
  const content = Uint8Array.from(binString, m => m.codePointAt(0)!);

  return new TextDecoder().decode(content);
};

export const toBase64 = (content: string) => {
  const bytes = new TextEncoder().encode(content);
  const binString = Array.from(bytes, byte => String.fromCodePoint(byte)).join('');

  return btoa(binString);
};

export const getTransformedLocationFilterData = (
  locationFilterData: string
): LocationFilterItemValue => JSON.parse(fromBase64(locationFilterData));

export const getStringifiedLocationFilterData = (
  locationFilterData: LocationFilterItemValue
): string => toBase64(JSON.stringify(locationFilterData));

export const getLocationFilterTypeFromItemType = (
  locationFilterItemValueType: LocationFilterItemType
): LocationFilterViewType | undefined => {
  let filterType: LocationFilterViewType | undefined = undefined;

  if (locationFilterItemValueType === LocationFilterItemType.COUNTRY) {
    filterType = LocationFilterViewType.COUNTRY;
  } else if (
    [
      LocationFilterItemType.GEOFENCE_RADIAL,
      LocationFilterItemType.GEOFENCE_POLYGON,
      LocationFilterItemType.GEOFENCE_LINE,
    ].includes(locationFilterItemValueType)
  ) {
    filterType = LocationFilterViewType.GEOFENCE;
  } else if (locationFilterItemValueType === LocationFilterItemType.POI) {
    filterType = LocationFilterViewType.POI;
  }

  return filterType;
};

export const getLocationFilterItemTypeFromShape = (
  shape: GEOJSONShapeType
): Exclude<LocationFilterItemType, LocationFilterItemType.COUNTRY> => {
  let filterItemType: LocationFilterItemType;

  switch (shape) {
    case GEOJSONShapeType.CIRCLE:
      filterItemType = LocationFilterItemType.GEOFENCE_RADIAL;
      break;
    case GEOJSONShapeType.POLYGON:
      filterItemType = LocationFilterItemType.GEOFENCE_POLYGON;
      break;
    case GEOJSONShapeType.LINE:
      filterItemType = LocationFilterItemType.GEOFENCE_LINE;
      break;
    case GEOJSONShapeType.POI:
      filterItemType = LocationFilterItemType.POI;
      break;
    default:
      throw new Error('Unknown shape type');
  }

  return filterItemType;
};
