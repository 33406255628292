import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { TextField } from '@/core/components/TextField';
import { Button, ButtonVariant } from '@/core/components/Button';
import { SelectComponent } from '@/core/components/Select';
import { ColorPicker } from '@/core/components/ColorPicker';
import { colorPickerColors } from '@/core/constants/colors';
import { IconPicker } from '@/core/components/IconPicker';
import { SelectOptionType } from '@/core/components/Select/SelectComponent.types';

import { getFoldersListSelector } from '@/features/Geofeatures/store';

import { FieldsContainer, ButtonsContainer, PickersRow } from './PolygonGeofenceForm.styles';
import { PolygonGeofenceFormData, PolygonGeofenceFormProps } from './PolygonGeofenceForm.types';

export const PolygonGeofenceForm = ({
  withoutFolder,
  defaultValues = {
    name: '',
    description: '',
    color: colorPickerColors.color1,
    icon: 'Flag',
  },
  onSubmit,
  onColorChange,
  onCancel,
}: PolygonGeofenceFormProps) => {
  const isMounted = useRef(false);
  const geofeaturesFoldersList = useSelector(getFoldersListSelector);

  const schema = useMemo(() => {
    const baseSchema = {
      name: yup.string().required(),
      folder: yup.object().nullable(),
      description: yup.string(),
      tags: yup.array().of(
        yup.object().shape({
          label: yup.string().required(),
          value: yup.string().required(),
        })
      ),
      color: yup.string().required(),
      icon: yup.string().required(),
    };

    if (withoutFolder) {
      // @ts-expect-error folder is not required
      delete baseSchema.folder;
    }

    return yup.object().shape(baseSchema);
  }, [withoutFolder]);

  const formMethods = useForm<PolygonGeofenceFormData>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const foldersList = useMemo<Array<SelectOptionType<string | number>>>(
    () =>
      geofeaturesFoldersList.map(folder => ({
        label: folder.name,
        value: folder.id,
      })),
    [geofeaturesFoldersList]
  );

  const { handleSubmit, watch, control, formState } = formMethods;

  const polygonColor = watch('color');

  const isFormSubmitting = formState.isSubmitting;

  useEffect(() => {
    if (isMounted.current) {
      onColorChange(polygonColor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polygonColor]);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FieldsContainer>
          <PickersRow>
            <Controller
              control={control}
              name="color"
              render={({ field: { value, onChange } }) => (
                <ColorPicker
                  onColorChange={onChange}
                  label="Color"
                  color={value}
                />
              )}
            />
            <Controller
              control={control}
              name="icon"
              render={({ field: { value, onChange } }) => (
                <IconPicker
                  onIconChange={onChange}
                  label="Icon"
                  icon={value}
                />
              )}
            />
          </PickersRow>
          <TextField
            name="name"
            label="Name"
          />
          {!withoutFolder && (
            <Controller
              control={control}
              name="folder"
              render={({ field: { name, value, onChange } }) => (
                <SelectComponent
                  label="Folder"
                  name={name}
                  value={value}
                  onChange={onChange}
                  placeholder="Select folder"
                  options={foldersList}
                />
              )}
            />
          )}
          <TextField
            name="description"
            label="Description"
          />
          <Controller
            name="tags"
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <SelectComponent
                creatable
                isMulti
                name={name}
                label="Tags"
                value={value}
                placeholder="Select tags"
                onChange={onChange}
              />
            )}
          />
        </FieldsContainer>
        <ButtonsContainer>
          <Button
            type="submit"
            disabled={isFormSubmitting}
          >
            SAVE
          </Button>
          <Button
            variant={ButtonVariant.TERTIARY}
            onClick={onCancel}
          >
            CANCEL
          </Button>
        </ButtonsContainer>
      </form>
    </FormProvider>
  );
};
