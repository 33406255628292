import { useEffect, useMemo, useRef } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Units } from '@turf/turf';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSelector } from 'react-redux';

import { MultiButton } from '@/core/components/MultiButton';
import { TextField } from '@/core/components/TextField';
import { Button, ButtonVariant } from '@/core/components/Button';
import { SelectComponent } from '@/core/components/Select';
import { ColorPicker } from '@/core/components/ColorPicker';
import { colorPickerColors } from '@/core/constants/colors';
import { IconPicker } from '@/core/components/IconPicker';
import { SelectOptionType } from '@/core/components/Select/SelectComponent.types';

import { getFoldersListSelector } from '@/features/Geofeatures/store';

import {
  RadiusInput,
  RadiusRow,
  FieldsContainer,
  ButtonsContainer,
  PickersRow,
} from './LineGeofenceForm.styles';
import { LineGeofenceFormData, LineGeofenceFormProps } from './LineGeofenceForm.types';

const bufferRadiusUnits: Array<{
  label: string;
  value: Units;
}> = [
  {
    label: 'km',
    value: 'kilometers',
  },
  {
    label: 'miles',
    value: 'miles',
  },
];

export const LineGeofenceForm = ({
  withoutFolder,
  defaultValues = {
    name: '',
    description: '',
    color: colorPickerColors.color1,
    icon: 'Flag',
  },
  onSubmit,
  onRadiusChange,
  onColorChange,
  onCancel,
}: LineGeofenceFormProps) => {
  const isMounted = useRef(false);
  const geofeaturesFoldersList = useSelector(getFoldersListSelector);

  const schema = useMemo(() => {
    const baseSchema = {
      radiusValue: yup
        .number()
        .transform((value, originalVal) => (originalVal === '' ? 0 : value))
        .nullable(),
      radiusUnit: yup.string(),
      name: yup.string().required(),
      folder: yup.object().required(),
      description: yup.string(),
      tags: yup.array().of(
        yup.object().shape({
          label: yup.string().required(),
          value: yup.string().required(),
        })
      ),
      color: yup.string().required(),
      icon: yup.string().required(),
    };

    if (withoutFolder) {
      // @ts-expect-error folder is not required
      delete baseSchema.folder;
    }

    return yup.object().shape(baseSchema);
  }, [withoutFolder]);

  const formMethods = useForm<LineGeofenceFormData>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const foldersList = useMemo<Array<SelectOptionType>>(
    () =>
      geofeaturesFoldersList.map(folder => ({
        label: folder.name,
        value: folder.id,
      })),
    [geofeaturesFoldersList]
  );

  const { handleSubmit, watch, control, formState } = formMethods;

  const isFormSubmitting = formState.isSubmitting;

  const radiusValue = watch('radiusValue');
  const radiusUnit = watch('radiusUnit');
  const circleColor = watch('color');

  useEffect(() => {
    if (isMounted.current) {
      onRadiusChange({
        value: radiusValue,
        unit: radiusUnit,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radiusUnit, radiusValue]);

  useEffect(() => {
    if (isMounted.current) {
      onColorChange(circleColor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [circleColor]);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FieldsContainer>
          <PickersRow>
            <Controller
              control={control}
              name="color"
              render={({ field: { value, onChange } }) => (
                <ColorPicker
                  onColorChange={onChange}
                  label="Color"
                  color={value}
                />
              )}
            />
            <Controller
              control={control}
              name="icon"
              render={({ field: { value, onChange } }) => (
                <IconPicker
                  onIconChange={onChange}
                  label="Icon"
                  icon={value}
                />
              )}
            />
          </PickersRow>

          <TextField
            name="name"
            label="Name"
          />
          {!withoutFolder && (
            <Controller
              control={control}
              name="folder"
              render={({ field: { name, value, onChange } }) => (
                <SelectComponent
                  label="Folder"
                  name={name}
                  value={value}
                  onChange={onChange}
                  placeholder="Select folder"
                  options={foldersList}
                />
              )}
            />
          )}
          <TextField
            name="description"
            label="Description"
          />
          <Controller
            name="tags"
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <SelectComponent
                creatable
                isMulti
                name={name}
                label="Tags"
                value={value}
                placeholder="Select tags"
                onChange={onChange}
              />
            )}
          />
          <RadiusRow>
            <RadiusInput>
              <TextField
                label="Radius"
                name="radiusValue"
                type="number"
              />
            </RadiusInput>
            <Controller
              control={control}
              name="radiusUnit"
              render={({ field: { value, onChange } }) => (
                <MultiButton
                  isSmall
                  active={value}
                  tabsData={bufferRadiusUnits}
                  onChange={onChange}
                />
              )}
            />
          </RadiusRow>
        </FieldsContainer>
        <ButtonsContainer>
          <Button
            type="submit"
            disabled={isFormSubmitting}
          >
            SAVE
          </Button>
          <Button
            type="submit"
            variant={ButtonVariant.TERTIARY}
            onClick={onCancel}
          >
            CANCEL
          </Button>
        </ButtonsContainer>
      </form>
    </FormProvider>
  );
};
