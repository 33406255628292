import { useCallback, useMemo } from 'react';
import { Units } from '@turf/turf';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { TextField } from '@/core/components/TextField';
import { MultiButton } from '@/core/components/MultiButton';
import { Button, ButtonVariant } from '@/core/components/Button';
import { useDrawGeofeaturesOnMap } from '@/core/hooks/useDrawGeofeaturesOnMap';
import { GEOJSONData } from '@/core/interfaces/geojsons';
import {
  LocationFilterItemType,
  LocationGeofenceFilterItem,
  LocationGeofenceFilterLineItemValue,
  LocationPoiFilterItem,
  LocationPoiFilterItemValue,
} from '@/core/components/MapFilters/LocationFilter/LocationFilter.types';

import { getStringifiedLocationFilterData } from '@/utils/locations';

import { AdjustGeofenceRadiusProps, FormData } from './AdjustGeofenceRadius.types';
import { ButtonsContainer, RadiusInput, RadiusRow } from './AdjustGeofenceRadius.styles';

const radiusUnits: Array<{
  label: string;
  value: Units;
}> = [
  {
    label: 'km',
    value: 'kilometers',
  },
  {
    label: 'miles',
    value: 'miles',
  },
];

const schema = yup.object().shape({
  radiusValue: yup.number().transform((value, originalVal) => (originalVal === '' ? 0 : value)),
  radiusUnit: yup.string(),
});

export const AdjustGeofenceRadius = ({
  geofencesList,
  onSubmit,
  onCancel,
}: AdjustGeofenceRadiusProps) => {
  const formMethods = useForm<FormData>({
    defaultValues: {
      radiusValue: 0,
      radiusUnit: 'kilometers',
    },
    resolver: yupResolver(schema),
  });

  const { handleSubmit, control, watch } = formMethods;

  const radiusValue = watch('radiusValue');
  const radiusUnit = watch('radiusUnit');

  const adjustedGeofences = useMemo<Array<GEOJSONData>>(
    () =>
      geofencesList.map(geofence => {
        if (geofence.transformedLocationValue.locationItemType === LocationFilterItemType.POI) {
          return {
            ...geofence.transformedLocationValue.value,
            properties: {
              ...geofence.transformedLocationValue.value.properties,
              zoneRadius: radiusValue,
              zoneRadiusUnit: radiusUnit,
            },
          };
        }

        if (
          geofence.transformedLocationValue.locationItemType ===
          LocationFilterItemType.GEOFENCE_LINE
        ) {
          return {
            ...geofence.transformedLocationValue.value,
            properties: {
              ...geofence.transformedLocationValue.value.properties,
              radius: radiusValue,
              radiusUnit,
            },
          };
        }

        return geofence.transformedLocationValue.value;
      }),

    [geofencesList, radiusUnit, radiusValue]
  );

  useDrawGeofeaturesOnMap({
    geofencesList: adjustedGeofences,
    idSuffix: 'radius-zone',
  });

  const onFormSubmit = useCallback(
    (data: FormData) => {
      const geofencesWithRadiusZone = geofencesList.map<
        LocationGeofenceFilterItem | LocationPoiFilterItem
      >(geofence => {
        if (geofence.transformedLocationValue.locationItemType === LocationFilterItemType.POI) {
          const geofenceValue: LocationPoiFilterItemValue = {
            locationItemType: geofence.transformedLocationValue.locationItemType,
            value: {
              ...geofence.transformedLocationValue.value,
              properties: {
                ...geofence.transformedLocationValue.value.properties,
                zoneRadius: data.radiusValue,
                zoneRadiusUnit: data.radiusUnit,
              },
            },
          };

          return {
            ...(geofence as LocationPoiFilterItem),
            transformedLocationValue: geofenceValue,
            value: getStringifiedLocationFilterData(geofenceValue),
          } satisfies LocationPoiFilterItem;
        }

        if (
          geofence.transformedLocationValue.locationItemType ===
          LocationFilterItemType.GEOFENCE_LINE
        ) {
          const geofenceValue: LocationGeofenceFilterLineItemValue = {
            locationItemType: geofence.transformedLocationValue.locationItemType,
            value: {
              ...geofence.transformedLocationValue.value,
              properties: {
                ...geofence.transformedLocationValue.value.properties,
                radius: data.radiusValue,
                radiusUnit: data.radiusUnit,
              },
            },
          };

          return {
            ...(geofence as LocationGeofenceFilterItem),
            transformedLocationValue: geofenceValue,
            value: getStringifiedLocationFilterData(geofenceValue),
          } satisfies LocationGeofenceFilterItem;
        }

        return geofence;
      });

      onSubmit(geofencesWithRadiusZone);
    },
    [geofencesList, onSubmit]
  );

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <RadiusRow>
          <RadiusInput>
            <TextField
              label="Radius"
              name="radiusValue"
              type="number"
            />
          </RadiusInput>
          <Controller
            control={control}
            name="radiusUnit"
            render={({ field: { value, onChange } }) => (
              <MultiButton
                isSmall
                active={value}
                tabsData={radiusUnits}
                onChange={onChange}
              />
            )}
          />
        </RadiusRow>
        <ButtonsContainer>
          <Button type="submit">SAVE</Button>
          <Button
            type="submit"
            variant={ButtonVariant.TERTIARY}
            onClick={onCancel}
          >
            CANCEL
          </Button>
        </ButtonsContainer>
      </form>
    </FormProvider>
  );
};
