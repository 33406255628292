import { useTheme } from 'styled-components';

import { Avatar } from '@/core/components/Layout/Avatar';
import { Paragraph } from '@/core/components/Typography';
import { Icon } from '@/core/components/Icons';

import { CoworkersList, CoworkerItem, RoleBadge, DeleteButton } from './CoworkerList.styles';
import { CoworkerListProps } from './CoworkerList.types';

export const CoworkerList = ({ coworkers, handleRemoveCoworker }: CoworkerListProps) => {
  const { colors } = useTheme();

  return (
    <CoworkersList>
      {coworkers.map(coworker => (
        <CoworkerItem key={coworker.id}>
          <Avatar src={coworker.photoUrl} />
          <Paragraph
            type="normal"
            color={colors.secondaryText}
          >
            {coworker.name}
          </Paragraph>
          <Paragraph
            type="small-normal"
            color={colors.secondaryText}
          >
            {coworker.email}
          </Paragraph>
          <RoleBadge>{coworker.role}</RoleBadge>
          <DeleteButton onClick={() => handleRemoveCoworker(coworker.id)}>
            <Icon name="Close" />
          </DeleteButton>
        </CoworkerItem>
      ))}
    </CoworkersList>
  );
};
