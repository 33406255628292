import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ReportSourceType } from '@/core/interfaces/common';
import { applyAllFilters, applyFilter, resetFilters } from '@/core/store/reducers/filtersSlice';

import { ReportResponseItem, ReportsState } from '@/features/Reports/interfaces';

const initialState: ReportsState = {
  horizonReportsList: [],
  lookoutReportsList: [],
  activeReportId: null,
};

export const reportDetailsSlice = createSlice({
  name: 'reportDetails',
  initialState,
  reducers: {
    openDetailsModal: (state, { payload }: PayloadAction<string | number>) => {
      state.activeReportId = payload;
    },
    closeDetailsModal: state => {
      state.activeReportId = null;
    },
    appendHorizonReports: (state, { payload }: PayloadAction<Array<ReportResponseItem>>) => {
      const reportsList = payload.map(item => ({
        ...item,
        reportSourceType: ReportSourceType.HORIZON,
        wasOpened: false,
      }));

      state.horizonReportsList.push(...reportsList);
    },
    appendLookoutReports: (state, { payload }: PayloadAction<Array<ReportResponseItem>>) => {
      const reportsList = payload.map(item => ({
        ...item,
        reportSourceType: ReportSourceType.LOOKOUT,
        wasOpened: false,
      }));

      state.lookoutReportsList.push(...reportsList);
    },
    markReportAsOpened: (
      state,
      {
        payload: { reportId, reportSourceType },
      }: PayloadAction<{
        reportId: number;
        reportSourceType: ReportSourceType;
      }>
    ) => {
      if (reportSourceType === ReportSourceType.HORIZON) {
        const report = state.horizonReportsList.find(item => item.reportId === reportId);

        if (report) {
          report.wasOpened = true;
        }
      }

      if (reportSourceType === ReportSourceType.LOOKOUT) {
        const report = state.lookoutReportsList.find(item => item.reportId === reportId);

        if (report) {
          report.wasOpened = true;
        }
      }
    },
  },
  extraReducers: builder =>
    builder
      .addCase(applyFilter, state => {
        state.horizonReportsList = [];
        state.lookoutReportsList = [];
      })
      .addCase(applyAllFilters, state => {
        state.horizonReportsList = [];
        state.lookoutReportsList = [];
      })
      .addCase(resetFilters, state => {
        state.horizonReportsList = [];
        state.lookoutReportsList = [];
      }),
});

export const {
  appendHorizonReports,
  appendLookoutReports,
  openDetailsModal,
  closeDetailsModal,
  markReportAsOpened,
} = reportDetailsSlice.actions;

export default reportDetailsSlice.reducer;
