import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { ConfigState } from '@/core/interfaces/state';
import { RootState } from '@/core/interfaces/store';
import { getCategories, getCountries } from '@/core/store/actions/config';

import { decodeHtml, getActionPrefix } from '@/utils/helpers';

const actionPrefix = getActionPrefix('config');

export const initialState: ConfigState = {
  appHydrated: false,
  appInitialized: false,
  drawingOnMapType: null,
  categoriesList: [],
  countriesList: [],
};

const configSlice = createSlice({
  extraReducers: builder =>
    builder
      .addCase(HYDRATE, state => ({
        ...state,
        appHydrated: true,
      }))
      .addCase(getCategories.fulfilled, (state, { payload }) => {
        state.categoriesList = payload.map(category => ({
          ...category,
          name: decodeHtml(category.name),
        }));
      })
      .addCase(getCountries.fulfilled, (state, { payload }) => {
        state.countriesList = payload;
      }),
  initialState,
  name: actionPrefix,
  reducers: {
    initializeApp: state => ({
      ...state,
      appInitialized: true,
    }),
    enableDrawingOnMap: (state, { payload }: PayloadAction<ConfigState['drawingOnMapType']>) => {
      state.drawingOnMapType = payload;
    },
    disableDrawingOnMap: state => {
      state.drawingOnMapType = null;
    },
  },
});

export const { initializeApp, disableDrawingOnMap, enableDrawingOnMap } = configSlice.actions;

const configSelector = (state: RootState): ConfigState => state.config;

export const drawingOnMapTypeSelector = createSelector(
  configSelector,
  state => state.drawingOnMapType
);

export const getCategoriesForFilterSelector = createSelector(
  configSelector,
  state => state.categoriesList
);

export const getCategoriesSelector = createSelector(configSelector, state => state.categoriesList);

export const getCountriesSelector = createSelector(configSelector, state => state.countriesList);

export default configSlice.reducer;
