import { ButtonVariant } from '@/core/components/Button/Button.types';

import { ReportImpact, UserRole } from '../interfaces/common';

export enum LoginStatesValues {
  LOADING = 'loading',
  AUTHENTICATED = 'authenticated',
  UNAUTHENTICATED = 'unauthenticated',
}

export type LoginStates =
  | LoginStatesValues.AUTHENTICATED
  | LoginStatesValues.LOADING
  | LoginStatesValues.UNAUTHENTICATED;

export enum UnrestrictedEnpoints {
  CREATE = 'login/',
  REFRESH = 'token/refresh/',
  LOGOUT = 'logout/',
}

type ButtonStateColors = {
  backgroundDefault: string;
  backgroundHover: string;
  backgroundActive: string;
  textDefault: string;
};

export type ThemeColors = {
  teal: string;
  white: string;
  primaryText: string;
  secondaryText: string;
  tertiaryButtonText: string;
  activeButtonText: string;
  active: string;
  warning: string;
  border: string;
  modalBackground: string;
  navbarIcon: string;
  addButton: string;
  mapBackground: string;
  badgeText: {
    light: string;
    dark: string;
  };
  background: {
    extraLight: string;
    light: string;
    medium: string;
    dark: string;
  };
  impact: {
    [ReportImpact.LOW]: {
      backgroundColor: string;
      textColor: string;
    };
    [ReportImpact.MEDIUM]: {
      backgroundColor: string;
      textColor: string;
    };
    [ReportImpact.HIGH]: {
      backgroundColor: string;
      textColor: string;
    };
    [ReportImpact.EXTREME]: {
      backgroundColor: string;
      textColor: string;
    };
  };
  reliability: {
    unknown: string;
    low: string;
    medium: string;
    high: string;
  };
  category: {
    Cybersecurity: string;
    Economy: string;
    'Natural disaster': string;
    Politics: string;
    Safety: string;
    Sector: string;
    Security: string;
    Travel: string;
  };
  categoryIcon: string;
  filter: {
    primaryText: string;
    hoverText: string;
    filterBadgeBgColor: string;
  };
  focuses: {
    primaryText: string;
    background: string;
    focusBackground: string;
    active: string;
    activeButtonText: string;
  };
  geofenceCreateItem: {
    textColor: {
      active: string;
      inactive: string;
    };
    radialBackground: {
      active: string;
      inactive: string;
    };
    polygonBackground: {
      active: string;
      inactive: string;
    };
    lineBackground: {
      active: string;
      inactive: string;
    };
  };
  accountRole: string;
  button: {
    [ButtonVariant.PRIMARY]: ButtonStateColors;
    [ButtonVariant.SECONDARY]: ButtonStateColors;
    [ButtonVariant.TERTIARY]: ButtonStateColors;
    [ButtonVariant.WARNING]: ButtonStateColors;
  };
  tags: {
    tagColor: string;
  };
  graph: {
    gridColor: string;
    lineColors: Array<string>;
  };
  geofenceDrawing: {
    editVertexBackground: string;
    editStroke: string;
  };
};

export const datepickerMonthsArray = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const FEATURE_HOVER_TIMEOUT = 300;

export const webAppRoles = [
  UserRole.MANAGEMENT_ADMIN,
  UserRole.TEAM_LEADER,
  UserRole.ANALYST,
  UserRole.VIEWER,
];
