import { useEffect, useState, useRef, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import { Paragraph } from '@/core/components/Typography';
import { useOnClickOutside } from '@/core/hooks/useOnClickOutside';
import { Input } from '@/core/components/Input';
import { Icon } from '@/core/components/Icons';
import { Avatar } from '@/core/components/Layout/Avatar';
import { useAppDispatch } from '@/core/store/store';
import { useAuthenticatedSession } from '@/core/hooks/useAuthenticatedSession';
import { CoworkerList } from '@/core/components/CoworkerList';

import { getOrganizationUsers, getUserSelector } from '@/features/Auth/store';

import { ShareInputProps, Coworker } from './ShareInput.types';
import {
  SearchWrapper,
  SuggestionsList,
  Suggestion,
  RoleBadge,
  AddButton,
  SuggestionContent,
} from './ShareInput.styles';

export const ShareInput = ({
  selectedCoworkersIds,
  onSelect,
  handleRemoveSelectedCoworker,
}: ShareInputProps) => {
  const userData = useSelector(getUserSelector);
  const { colors } = useTheme();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState<Array<Coworker>>([]);
  const [suggestions, setSuggestions] = useState<Array<Coworker>>([]);

  const dispatch = useAppDispatch();

  const searchBarRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(searchBarRef, () => setFilteredSuggestions([]));

  const selectedCoworkersList = useMemo(
    () =>
      selectedCoworkersIds.reduce((acc, coworkerId) => {
        const coworker = suggestions.find(suggestion => suggestion.id === coworkerId);

        if (!coworker) {
          return acc;
        }

        return [...acc, coworker];
      }, [] as Array<Coworker>),
    [selectedCoworkersIds, suggestions]
  );

  const handleSearch = (term: string) => {
    if (term.length >= 2 && !suggestions.some(suggestion => suggestion.name === term)) {
      const filtered = suggestions.filter(
        suggestion =>
          suggestion.name.toLowerCase().includes(term.toLowerCase()) &&
          !selectedCoworkersIds.some(coworker => coworker === suggestion.id)
      );

      setFilteredSuggestions(filtered);
    } else {
      setFilteredSuggestions([]);
    }
  };

  const onSelectSuggestion = (suggestion: Coworker) => {
    setSearchTerm('');
    onSelect(suggestion.id);
  };

  useEffect(() => {
    handleSearch(searchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useAuthenticatedSession(() => {
    const getUsersList = async () => {
      if (userData) {
        const users = unwrapResult(await dispatch(getOrganizationUsers(userData.organization.id)));

        const coworkers = users.map<Coworker>(user => ({
          id: user.id,
          email: user.email,
          name: `${user.firstName} ${user.lastName}`,
          role: user.groups[0],
          photoUrl: 'https://placehold.co/150',
        }));

        setSuggestions(coworkers);
      }
    };

    getUsersList();
  }, []);

  return (
    <>
      <SearchWrapper ref={searchBarRef}>
        <Input
          placeholder="Search for a user or an organization"
          autoComplete="off"
          suffixIcon={<Icon name="Search" />}
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
        {filteredSuggestions.length > 0 && (
          <SuggestionsList>
            {filteredSuggestions.map((suggestion, index) => (
              <Suggestion key={index}>
                <SuggestionContent>
                  <Avatar src={suggestion.photoUrl} />
                  <Paragraph
                    type="normal"
                    color={colors.secondaryText}
                  >
                    {suggestion.name}
                  </Paragraph>
                  <Paragraph
                    type="small-normal"
                    color={colors.secondaryText}
                  >
                    {suggestion.email}
                  </Paragraph>
                  <RoleBadge>{suggestion.role}</RoleBadge>
                </SuggestionContent>
                <AddButton
                  onClick={() => onSelectSuggestion(suggestion)}
                  type="button"
                >
                  Add
                </AddButton>
              </Suggestion>
            ))}
          </SuggestionsList>
        )}
      </SearchWrapper>
      {selectedCoworkersList.length > 0 && (
        <CoworkerList
          coworkers={selectedCoworkersList}
          handleRemoveCoworker={handleRemoveSelectedCoworker}
        />
      )}
    </>
  );
};
