import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';

import { FeatureNames } from '@/core/interfaces/store';
import { DEFAULT_DATE_AND_TIME_FORMAT, DEFAULT_DATE_FORMAT } from '@/core/constants/time';
import { GEOJSONCollectionData, GEOJSONData } from '@/core/interfaces/geojsons';
import { CategoryFilterItem } from '@/core/components/MapFilters/CategoryFilter';
import { CategoriesResponse } from '@/core/interfaces/categories';

export const isBrowser = !!(
  // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
  (typeof window !== 'undefined' && window?.document && window?.document?.createElement)
);

export const getActionPrefix = (featureName: FeatureNames) => featureName.toUpperCase();

export const isLocaleRTL = (locale: string | undefined) => {
  if (!locale) {
    return false;
  }

  return ['ar', 'arc', 'dv', 'fa', 'ha', 'he', 'khw', 'ks', 'ku', 'ps', 'ur', 'yi'].includes(
    locale.split('-')[0]
  );
};

export const importLocaleMessages = async (locale: string | undefined) => {
  if (!locale) {
    return false;
  }

  try {
    return (await import(`@/lang/compiled/${locale}.json`)).default;
  } catch (e) {
    return undefined;
  }
};

export const decodeHtml = (html: string) => {
  const txt = document.createElement('textarea');

  txt.innerHTML = html;

  return txt.value;
};

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '1 s',
    m: '1 m',
    mm: '%d m',
    h: '1 h',
    hh: '%d h',
    d: '1 d',
    dd: '%d d',
    M: '1 mo',
    MM: '%d mo',
    y: '1 y',
    yy: '%d y',
  },
});

export const getTimeAndDate = ({
  dateStr,
  enterFormat = DEFAULT_DATE_AND_TIME_FORMAT,
  exitFormat = DEFAULT_DATE_AND_TIME_FORMAT,
  fromNow,
}: {
  dateStr: string;
  enterFormat?: string;
  exitFormat?: string;
  fromNow?: true;
}) => {
  const date = dayjs.utc(dateStr, enterFormat);

  if (fromNow) {
    return date.fromNow(true);
  }

  return date.format(exitFormat);
};

export const getTimeAndDateTZ = (dateStr: string, exitFormat: string, tz = 'CET') => {
  return dayjs(dateStr, DEFAULT_DATE_AND_TIME_FORMAT).tz(tz).format(exitFormat);
};

export const formatToDefaultDate = (date: Date | null) => {
  return dayjs(date).format(DEFAULT_DATE_FORMAT);
};

export const hexToRGBA = (hex: string): string => {
  const matchResult = hex.match(/\w\w/g);
  const [r, g, b] = matchResult ? matchResult.map(x => parseInt(x, 16)) : [0, 0, 0];

  return `${r},${g},${b}`;
};

const reverseGeoJSONCoordinates = (geoJSON: GEOJSONData): GEOJSONData => {
  const { geometry } = geoJSON;

  if (!geometry?.coordinates) {
    return geoJSON;
  }

  let reversedCoordinates = [];

  if (geometry.type === 'LineString') {
    reversedCoordinates = geometry.coordinates.map((coords: Array<number>) => {
      return coords.slice().reverse();
    });
  } else if (geometry.type === 'Point') {
    reversedCoordinates = geometry.coordinates.slice().reverse();
  } else {
    reversedCoordinates = geometry.coordinates.map((coords: Array<Array<number>>) => {
      return coords.map(coord => {
        return coord.slice().reverse();
      });
    });
  }

  return {
    ...geoJSON,
    geometry: {
      ...geometry,
      // @ts-expect-error coordinates are correctly mapped
      coordinates: reversedCoordinates,
    },
  };
};

export const createGeoJSONStringForExport = (geoJSON: Array<GEOJSONData>): string => {
  if (!geoJSON.length) {
    return '';
  }

  const reversedGeoJSON = geoJSON.map(geo => reverseGeoJSONCoordinates(geo));

  let combinedGeoJSON = {};

  if (reversedGeoJSON.length === 1) {
    combinedGeoJSON = reversedGeoJSON[0];
  } else {
    combinedGeoJSON = {
      type: 'FeatureCollection',
      features: reversedGeoJSON,
    };
  }

  return JSON.stringify(combinedGeoJSON, undefined, 2);
};
export const prepareGeoJSONForImport = (
  geoJSON: string | GEOJSONData | GEOJSONCollectionData,
  folderId?: number
): GEOJSONData | GEOJSONCollectionData => {
  const geoJSONData = typeof geoJSON === 'string' ? JSON.parse(geoJSON) : geoJSON;

  if (geoJSONData.type === 'FeatureCollection') {
    const features = geoJSONData.features.map((feature: GEOJSONData) => {
      if (folderId) {
        feature.properties.folder = folderId;
      }

      return reverseGeoJSONCoordinates(feature);
    });

    return {
      type: 'FeatureCollection',
      features,
    };
  }

  if (folderId) {
    geoJSONData.properties.folder = folderId;
  }

  return reverseGeoJSONCoordinates(geoJSONData);
};

export const transformCategoriesForFilters = (categories: CategoriesResponse) => {
  return categories.reduce(
    (acc, category) => {
      if (!acc[category.mainCategory]) {
        acc[category.mainCategory] = [];
      }

      acc[category.mainCategory].push({
        id: category.id,
        isChecked: false,
        label: category.name,
        value: category.id,
      });

      return acc;
    },
    {} as { [key: string]: Array<CategoryFilterItem> }
  );
};
